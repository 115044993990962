export const DATA = [
	{ label: 'AmorSaúde', value: 'amor_saude' },
	{ label: 'Cartão de TODOS', value: 'cartao_de_todos' },
	{ label: "EPT's", value: 'epts' },
	{ label: 'Hamonir', value: 'hamonir' },
	{ label: 'Pet de TODOS', value: 'pet_todos' },
	{ label: 'Visão de TODOS', value: 'visao_de_todos' },
	{ label: "Holding's", value: 'holdings' },
	{ label: 'Royal Face', value: 'royal_face' },
	{ label: 'MaisTODOS', value: 'mais_todos' },
	{ label: 'Outros', value: 'others' },
	{ label: 'Não faço parte do grupo', value: 'without_type' },
];
