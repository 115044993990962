import { useCallback, useEffect, useState } from 'react';
import { BoxGroup } from './style';
import Card from '../../../../../../../components/Card';
import {
	Box,
	Checkbox,
	SelectField,
	SelectItem,
	Separator,
	TextField,
	Typography,
	unmaskString,
} from '@maistodos/design-system-web';
import { useFormContext } from 'react-hook-form';
import cep from 'cep-promise';
import { OwnerBusinessProps } from './types';
import { BusinessOwner, CompanyForm } from '../../../CompanyData/types';
import { validator } from '../../../../../../../utils/validator';
import OwnerRepresentant from '../Representant';
import { CloseButton } from '../OwnerPersonal/style';
import Icon from '../../../../../../../components/Icon';
import { annualBillingOptions } from '../../../../../constants';

const OwnerBusiness = ({ index, removeOwner, setIsValid }: OwnerBusinessProps) => {
	const { register, watch, formState, setValue, setError } = useFormContext<CompanyForm>();
	const { errors } = formState;

	const [withNumber, setWithNumber] = useState<boolean>(true);
	const [isLoadingCepPromise, setisLoadingCepPromise] = useState<boolean>(false);
	const [isCepPromiseSuccess, setIsCepPromiseSuccess] = useState<boolean>(false);
	const businessType = watch(`owners_business.${index}.business_type`) as unknown;
	const zipCode = watch(`owners_business.${index}.business_address.zip_code`);
	const viewAddress = isCepPromiseSuccess && zipCode?.length === 9;

	const handleZipCode = useCallback(async (): Promise<void> => {
		setIsCepPromiseSuccess(false);
		setisLoadingCepPromise(true);
		try {
			const businessAddress = await cep(zipCode);
			setValue(`owners_business.${index}.business_address.address_line`, businessAddress.street);
			setValue(`owners_business.${index}.business_address.city`, businessAddress.city);
			setValue(`owners_business.${index}.business_address.neighborhood`, businessAddress.neighborhood);
			setValue(`owners_business.${index}.business_address.state`, businessAddress.state);
			setValue(`owners_business.${index}.business_address.country`, 'BR');
			setIsCepPromiseSuccess(true);
		} catch {
			setIsCepPromiseSuccess(false);
			setError(`owners_business.${index}.business_address.zip_code`, {
				type: 'required',
				message: 'CEP inválido.',
			});
			setValue(`owners_business.${index}.business_address.address_line`, '');
			setValue(`owners_business.${index}.business_address.state`, '');
			setValue(`owners_business.${index}.business_address.city`, '');
			setValue(`owners_business.${index}.business_address.neighborhood`, '');
			setValue(`owners_business.${index}.business_address.building_number`, '');
			setValue(`owners_business.${index}.business_address.complement`, '');
			setWithNumber(true);
		} finally {
			setisLoadingCepPromise(false);
		}
	}, [cep, zipCode]);

	const handleNoNumber = useCallback(() => {
		setWithNumber((prev) => {
			const value = !prev;

			if (value) setValue(`owners_business.${index}.business_address.building_number`, '', { shouldValidate: true });
			if (!value)
				setValue(`owners_business.${index}.business_address.building_number`, 'S/N', { shouldValidate: true });

			return !prev;
		});
	}, [index]);

	const validateData = (data: BusinessOwner[]) => {
		for (const entry of data) {
			const { business_type, business_address, legal_representatives } = entry;

			if (!business_type) {
				return false;
			}

			if (
				!business_address ||
				business_address.zip_code?.length < 9 ||
				!business_address.address_line ||
				!business_address.building_number ||
				!business_address.neighborhood
			) {
				return false;
			}

			for (const representative of legal_representatives) {
				const { pep, address } = representative;
				if (!pep?.level) {
					return false;
				}
				if (
					!address ||
					address.zip_code?.length < 9 ||
					!address.address_line ||
					!address.building_number ||
					!address.neighborhood
				) {
					return false;
				}
			}
		}
		return true;
	};

	const validateFormFields = useCallback(() => {
		const valid = validateData(watch('owners_business'));
		setIsValid(valid);
	}, [watch()]);

	useEffect(() => {
		validateFormFields();
	}, [watch()]);

	useEffect(() => {
		if (zipCode?.length === 9) {
			void handleZipCode();
		}
	}, [zipCode]);

	return (
		<BoxGroup>
			<CloseButton
				css={{ position: 'absolute', right: '15px' }}
				title="Excluir sócio"
				onClick={() => removeOwner(index, 'business')}
			>
				<Icon name="Close" color="$neutral500" />
			</CloseButton>
			<Typography variant={'h5'} weight={'semibold'} color={'$neutral600'} css={{ marginBottom: '$spacing24' }}>
				Sócio {index + 1 > 1 && index + 1} - Pessoa jurídica
			</Typography>
			<Card title="Informe o CNPJ (digite apenas os números):" required>
				<TextField
					label="CNPJ"
					type="text"
					mask={'dd.ddd.ddd/dddd-dd'}
					value={watch(`owners_business.${index}.document_number`)}
					error={!!errors?.owners_business?.[index]?.document_number}
					helperText={errors.owners_business?.[index]?.document_number?.message}
					{...register(`owners_business.${index}.document_number`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 14,
							message: 'O campo não foi preenchido corretamente.',
						},
						validate: (e) => validator.isValidCNPJ(e),
					})}
				/>
			</Card>
			<Card title="Nome da empresa (razão social):" required>
				<TextField
					label="Razão Social"
					type="text"
					maxLength={128}
					value={watch(`owners_business.${index}.business_name`)}
					error={!!errors?.owners_business?.[index]?.business_name}
					helperText={errors.owners_business?.[index]?.business_name?.message}
					{...register(`owners_business.${index}.business_name`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
				/>
			</Card>
			<Card title="Nome fantasia (nome comercial):">
				<TextField
					label="Nome Fantasia"
					type="text"
					value={watch(`owners_business.${index}.trading_name`)}
					error={!!errors?.owners_business?.[index]?.trading_name}
					helperText={errors.owners_business?.[index]?.trading_name?.message}
					maxLength={128}
					{...register(`owners_business.${index}.trading_name`)}
				/>
			</Card>
			<Card title="Selecione o tipo da sua empresa:" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name={`business_type${index}`}
						label="LTDA - Sociedade Limitada"
						value="ltda"
						onChange={() => setValue(`owners_business.${index}.business_type`, 'ltda')}
						checked={businessType === 'ltda'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name={`business_type${index}`}
						label="S.A - Sociedade Anônima"
						value="sa"
						onChange={() => setValue(`owners_business.${index}.business_type`, 'sa')}
						checked={businessType === 'sa'}
					/>
				</Box>
				<Box>
					<Checkbox
						name={`business_type${index}`}
						label="TS - Terceiro Setor"
						value="ts"
						onChange={() => setValue(`owners_business.${index}.business_type`, 'ts')}
						checked={businessType === 'ts'}
					/>
				</Box>
			</Card>
			<Card title="E-mail" required>
				<TextField
					label="E-mail"
					type="text"
					maxLength={255}
					value={watch(`owners_business.${index}.email`)}
					error={!!errors?.owners_business?.[index]?.email}
					helperText={errors.owners_business?.[index]?.email?.message}
					{...register(`owners_business.${index}.email`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'E-mail inválido.',
						},
					})}
				/>
			</Card>
			<Card title="Principal número celular para contato com DDD:" required>
				<TextField
					label="Telefone"
					type="text"
					mask="(dd) ddddd-dddd"
					value={watch(`owners_business.${index}.phone.number`)}
					error={!!errors?.owners_business?.[index]?.phone?.number}
					helperText={errors.owners_business?.[index]?.phone?.number?.message}
					{...register(`owners_business.${index}.phone.number`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 15,
							message: 'O preenchimento é obrigatório.',
						},
						validate: (e) => validator.isValidPhone(unmaskString(e)) || 'Número de celular inválido.',
					})}
				/>
			</Card>
			<Card
				title="Percentual de participação"
				subtitle="Só é aceito números inteiros, favor se atentar que no final a soma das porcentagens devem ser 100%."
				required
			>
				<TextField
					label="Percentual de participação"
					type="text"
					mask={'ddd'}
					value={watch(`owners_business.${index}.participation_percentage`)}
					error={!!errors?.owners_business?.[index]?.participation_percentage}
					helperText={errors.owners_business?.[index]?.participation_percentage?.message}
					endAdornment="%"
					{...register(`owners_business.${index}.participation_percentage`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 1,
							message: 'O preenchimento é obrigatório.',
						},
						validate: (value) => (value && value > 100 ? 'Porcentagem inválida.' : true),
					})}
				/>
			</Card>
			<Card
				title="Faturamento anual da empresa:"
				subtitle="Caso a empresa ainda não tenha 1 ano, inserir a estimativa."
				required
			>
				<SelectField
					placeholder="Selecione o faturamento anual da empresa"
					error={!!errors?.owners_business?.[index]?.declared_annual_billing}
					helperText={errors.owners_business?.[index]?.declared_annual_billing?.message}
					value={watch(`owners_business.${index}.declared_annual_billing`)}
					{...register(`owners_business.${index}.declared_annual_billing`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
					onChange={(e) =>
						setValue(`owners_business.${index}.declared_annual_billing`, e.target.value, { shouldValidate: true })
					}
				>
					{annualBillingOptions.map((option) => (
						<SelectItem key={option.value} value={option.value}>
							{option.label}
						</SelectItem>
					))}
				</SelectField>
			</Card>
			<Card
				title={`Endereço completo da empresa sócia:`}
				subtitle={`Informe o endereço completo da empresa sócia: Rua, número, complemento (se houver), bairro, cidade e estado.`}
				required
			>
				<Box
					css={{
						width: '50%',
					}}
				>
					<TextField
						label="CEP"
						type="text"
						mask={'ddddd-ddd'}
						value={watch(`owners_business.${index}.business_address.zip_code`)}
						error={!!errors?.owners_business?.[index]?.business_address?.zip_code}
						helperText={errors.owners_business?.[index]?.business_address?.zip_code?.message}
						endAdornment={isLoadingCepPromise && <Icon name="Refresh" fontSize="$large" />}
						{...register(`owners_business.${index}.business_address.zip_code`, {
							required: {
								value: true,
								message: 'É necessário preencher com CEP.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'column',
						width: '50%',
						marginTop: '$spacing24',
					}}
				>
					<TextField
						label="Endereço"
						maxLength={250}
						type="text"
						value={watch(`owners_business.${index}.business_address.address_line`)}
						{...register(`owners_business.${index}.business_address.address_line`, {
							required: {
								value: true,
								message: 'Preenchimento obrigatório.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Número"
							mask="ddddddddd"
							type="text"
							value={watch(`owners_business.${index}.business_address.building_number`)}
							{...register(`owners_business.${index}.business_address.building_number`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled={!withNumber}
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Complemento"
							maxLength={150}
							type="text"
							value={watch(`owners_business.${index}.business_address.complement`)}
							{...register(`owners_business.${index}.business_address.complement`)}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ arginTop: '$spacing32', width: '50%' }}>
						<TextField
							label="Bairro"
							maxLength={200}
							type="text"
							value={watch(`owners_business.${index}.business_address.neighborhood`)}
							{...register(`owners_business.${index}.business_address.neighborhood`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Cidade"
							type="text"
							value={watch(`owners_business.${index}.business_address.city`)}
							{...register(`owners_business.${index}.business_address.city`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Estado"
							value={watch(`owners_business.${index}.business_address.state`)}
							type="text"
							{...register(`owners_business.${index}.business_address.state`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Checkbox
						name={`owners_business.${index}.without_number`}
						label="Endereço sem número?"
						value="false"
						onChange={handleNoNumber}
						checked={!withNumber}
					/>
				</Box>
			</Card>
			<Separator />
			<Typography variant={'h5'} weight={'semibold'} color={'$neutral600'} css={{ marginBottom: '$spacing24' }}>
				Representante Legal do sócio PJ
			</Typography>
			<OwnerRepresentant parentIndex={index} />
		</BoxGroup>
	);
};

export default OwnerBusiness;
