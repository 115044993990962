import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@maistodos/design-system-web';
import Card from '../../../components/Card';
import QRCode from 'react-qr-code';
import WarningAdvertisement from '../../../components/WarningAdvertisement';
import { StepsType } from './types';
import MixpanelTracker from '../../../libs/mixpanel/MixPanel';

const Finish = () => {
	const [step, setStep] = useState<StepsType>('step-1');

	useEffect(() => {
		if (step === 'step-1') MixpanelTracker.sendEvent('new_account_info_user');
		if (step === 'step-2') MixpanelTracker.sendEvent('new_account_success');
		if (step === 'step-3') MixpanelTracker.sendEvent('new_account_confirmed');
	}, [step]);

	useEffect(() => {
		sessionStorage.setItem('INTERNAL_BUSINESS_TYPE', '');
	}, []);

	return (
		<Box
			css={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				css={{
					marginTop: '$spacing40',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<img src={'/assets/logo-maistodos.svg'} width={240} height={60} />
			</Box>
			{step === 'step-1' && (
				<Box css={{ marginTop: '$spacing40', width: '60%', alignSelf: 'center' }}>
					<Card title="Importante" isH5Variant>
						<Box css={{ marginTop: '$spacing24' }}>
							<Typography variant="large" css={{ fontWeight: '$semibold', color: '$neutral600' }}>
								Agora precisamos que o representante legal, aquele que detém o poder administrativo da empresa, faça o
								cadastro em nosso aplicativo.
							</Typography>
						</Box>
					</Card>
					<Box css={{ marginTop: '$spacing24', display: 'flex', justifyContent: 'flex-end' }}>
						<Button onClick={() => setStep('step-2')}>Avançar</Button>
					</Box>
				</Box>
			)}
			{step === 'step-2' && (
				<Box
					css={{
						marginTop: '$spacing40',
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						css={{
							minWidth: '60%',
							display: 'flex',
							alignSelf: 'center',
							justifyContent: 'center',
						}}
					>
						<WarningAdvertisement text="Se você baixou o aplicativo e criou cadastro de usuário, clique em “Concluir”." />
					</Box>
					<Box
						css={{
							flexDirection: 'row',
							alignItems: 'center',
							display: 'flex',
							marginTop: '$spacing48',
							padding: '$spacing24',
							gap: '$spacing32',
							width: '55%',
							backgroundColor: '$neutral25',
							border: '1px solid #D2D2D2',
							borderRadius: '$medium',
						}}
					>
						<Box
							css={{
								flex: 1,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<QRCode value="https://linktr.ee/appmaistodos" size={181} />
							<Box css={{ marginTop: '$spacing16', width: '80%' }}>
								<Typography variant="small" css={{ color: '$neutral600', textAlign: 'center' }}>
									Leia o QR Code e baixe o app no seu celular.
								</Typography>
							</Box>
						</Box>
						<Box css={{ flex: 3 }}>
							<Typography variant="h5" css={{ fontWeight: '$bold', color: '$neutral600' }}>
								Já baixou o nosso aplicativo?
							</Typography>
							<Typography
								variant="large"
								css={{
									fontWeight: '$semibold',
									color: '$neutral600',
									marginTop: '$spacing24',
								}}
							>
								Precisamos que o proprietário, sócio ou representante legal faça o cadastro em nosso aplicativo. Só
								assim a conta será enviada para análise.
							</Typography>
						</Box>
					</Box>
					<Box css={{ marginTop: '$spacing32' }}>
						<Button onClick={() => setStep('step-3')}>Concluir</Button>
					</Box>
				</Box>
			)}
			{step === 'step-3' && (
				<Box css={{ marginTop: '$spacing40', width: '60%', alignSelf: 'center' }}>
					<Card title="Estamos quase lá!" isH5Variant>
						<Box css={{ marginTop: '$spacing24' }}>
							<Typography variant="large" css={{ fontWeight: '$semibold', color: '$neutral600' }}>
								Agora falta pouco para você ter sua conta MaisTodos Empresas.
							</Typography>
						</Box>
						<Box css={{ marginTop: '$spacing24' }}>
							<Typography variant="large" css={{ fontWeight: '$semibold', color: '$neutral600' }}>
								Em caso de dúvidas, basta enviar uma mensagem para o nosso suporte.
							</Typography>
						</Box>
					</Card>
				</Box>
			)}
		</Box>
	);
};

export default Finish;
