import { StepsProps } from './types';
import { Box, Typography } from '@maistodos/design-system-web';

const Steps = ({ currentStep, steps }: StepsProps) => {
	return (
		<>
			<Box css={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
				{steps.map((_, index) => {
					const isLastItem = index + 1 < steps.length
					const backgroundColor = currentStep === index ? '$brand500' : '$neutral200'
					return (
						<Box key={String(index)} css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: isLastItem ? 1 : 0 }}>
							<Box
								css={{
									minWidth: 25,
									height: 25,
									justifyContent: 'center',
									borderRadius: 999,
									alignItems: 'center',
									backgroundColor,
									display: 'flex',
								}}>
								<Typography
									variant="small"
									css={{ fontWeight: '$bold', color: '$neutral0' }}
								>{String(index + 1)}</Typography>
							</Box>
							{isLastItem && <Box css={{ backgroundColor, width: '100%', height: 2 }} />}
						</Box>
					)
				})}
			</Box>
			<Box css={{ marginTop: '$spacing0', display: 'flex', justifyContent: 'space-between' }}>
				{steps.map((title) => (
					<Box key={title} css={{ display: 'flex', position: 'relative' }}>
						<Box css={{ width: 25, height: 25 }} />
						<Typography
							variant="small"
							css={{
								fontWeight: '$bold',
								position: 'absolute',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								whiteSpace: 'nowrap',
							}}
						>
							{title}
						</Typography>
					</Box>
				))}
			</Box>
		</>
	);
}

export default Steps;
