import { Box, Typography } from '@maistodos/design-system-web';

import Card from '../components/Card';
import { OptionsCompanySize, type OptionsCompanyProps } from './types';
import { useNavigate } from 'react-router-dom';
import { useEffectMixPanelSendEvent } from '../../../libs/mixpanel';
import MixpanelTracker from '../../../libs/mixpanel/MixPanel';

const Home = () => {
	const navigate = useNavigate();
	const handleSelectCompanySize = (companySize: OptionsCompanySize) => {
		if (companySize === OptionsCompanySize.SMALL) MixpanelTracker.sendEvent('new_account_pjotinha');
		if (companySize === OptionsCompanySize.CORPORATE) MixpanelTracker.sendEvent('new_account_pjotao');

		navigate(`/form/${companySize}/1`);
	};

	const optionsTypeCompany: OptionsCompanyProps[] = [
		{
			id: 'small',
			option: OptionsCompanySize.SMALL,
			title: (
				<>
					<Typography variant="large" css={{ color: '$neutral600' }}>
						Minha empresa
					</Typography>
					<Typography variant="large" css={{ fontWeight: '$bold', color: '$neutral600' }}>
						possui apenas uma pessoa física
					</Typography>
					<Typography variant="large" css={{ color: '$neutral600' }}>
						no quadro societário
					</Typography>
				</>
			),
		},
		{
			id: 'corporate',
			option: OptionsCompanySize.CORPORATE,
			title: (
				<>
					<Typography variant="large" css={{ color: '$neutral600' }}>
						Minha empresa
					</Typography>
					<Typography variant="large" css={{ fontWeight: '$bold', color: '$neutral600' }}>
						possui mais de uma pessoa física, ou pelo menos uma pessoa jurídica no quadro societário,
					</Typography>
					<Typography variant="large" css={{ color: '$neutral600' }}>
						ou é uma S.A.
					</Typography>
				</>
			),
		},
	];

	useEffectMixPanelSendEvent({
		event: 'new_account_company_size',
	});

	return (
		<Box
			css={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				css={{
					marginTop: '$spacing40',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<img src={'/assets/logo-maistodos.svg'} width={240} height={60} />
			</Box>
			<Box
				css={{
					marginTop: '$spacing40',
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography variant="h5" css={{ fontWeight: '$bold', color: '$neutral600' }}>
					Abra sua conta MaisTodos Empresas
				</Typography>
				<Box
					css={{
						marginTop: '$spacing16',
					}}
				>
					<Typography variant="large" css={{ fontWeight: '$semibold', color: '$neutral600' }}>
						Selecione a descrição que mais se adequa a sua atividade empresarial
					</Typography>
				</Box>
			</Box>
			<Box
				css={{
					display: 'flex',
					marginTop: '$spacing24',
					flexDirection: 'column',
					width: '75%',
					alignSelf: 'center',
				}}
			>
				{optionsTypeCompany.map(({ title, option, id }) => (
					<Card
						key={id}
						title={title}
						onClick={() => {
							handleSelectCompanySize(option);
						}}
					/>
				))}
			</Box>
		</Box>
	);
};

export default Home;
