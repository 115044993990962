import { Box } from '@maistodos/design-system-web';

import type { CardProps } from './types';
import Icon from '../../../../components/Icon';

const Card = ({ title, onClick }: CardProps) => (
	<Box
		onClick={onClick}
		css={{
			backgroundColor: '$neutral25',
			border: '1px solid $neutral300',
			borderRadius: '$large',
			padding: '$spacing24',
			gap: '$spacing24',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginTop: '$spacing24',
			cursor: 'pointer',
		}}
	>
		<Box
			css={{
				display: 'flex',
				gap: '$spacing4',
			}}
		>
			{title}
		</Box>
		<Icon name="ChevronRight" color="$neutral500" />
	</Box>
);

export default Card;
