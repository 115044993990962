import { Navigate } from 'react-router-dom';

import { Toast } from '@maistodos/design-system-web';

import type { NavigateScreenType } from './types';

import Icon from '../../components/Icon';

export const NavigateScreen = ({
  refToast,
  routeToNavigate,
}: NavigateScreenType) => {
  return (
    <>
      <Toast
        ref={refToast}
        direction="top-right"
        icon={<Icon name="Close" color="$currentColor" />}
        title="Por favor preencha os dados corretamente."
        type="warning"
      />
      <Navigate to={routeToNavigate} replace />
    </>
  );
};
