import { AxiosResponse } from 'axios';
import { backofficeService } from '..';
import { CreateSmallBusinessPayload, CreateSmallBusinessData } from './types';

const createSmallBusiness = async (data: CreateSmallBusinessPayload): Promise<CreateSmallBusinessData> => {
	const { data: response } = await backofficeService.post<
		CreateSmallBusinessPayload,
		AxiosResponse<CreateSmallBusinessData>
	>(`/v1/business/kyc/small`, {
		...data,
	});
	return response;
};
export { createSmallBusiness };
