import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Initial, Home, Forms, Finish, NotFound } from '../modules';
import { PrivateRouter } from './PrivateRouter';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyForm } from '../modules/Forms/Corporate/screens/CompanyData/types';
import { CompanySmallForm } from '../modules/Forms/Small/types';

const routes = createBrowserRouter([
	{
		path: '/',
		element: <Initial />,
	},
	{
		path: "/",
		element: <PrivateRouter />,
		children: [
			{
				path: '/home',
				element: <Home />,
			},
			{
				path: '/form/:companySize/:step',
				element: <Forms />,
			},
			{
				path: '/finish',
				element: <Finish />,
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
]);

const Routes = () => {
	const methods = useForm<CompanyForm | CompanySmallForm>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'all',
	});
	return <FormProvider {...methods}><RouterProvider router={routes} /></FormProvider>
};

export default Routes;
