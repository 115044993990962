export enum OptionsCompanySize {
	SMALL = 'small',
	CORPORATE = 'corporate',
}

export type OptionsCompanyProps = {
	title: JSX.Element;
	option: OptionsCompanySize;
	id: string;
};
