import { Box, Typography } from '@maistodos/design-system-web';

import type { CardProps } from './types';

const Card = ({ children, title, subtitle, adornment, required, isH5Variant }: CardProps) => (
	<Box
		css={{
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: '$neutral25',
			border: '1px solid $neutral300',
			borderRadius: '$large',
			padding: '$spacing24',
		}}
	>
		{(title ?? adornment) && (
			<Box
				css={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: '$spacing24',
				}}
			>
				{title && (
					<Box css={{ display: 'flex', flexDirection: 'column' }}>
						<Typography variant={isH5Variant ? "h5" : "large"} weight="bold" css={{ color: '$neutral600' }}>
							{required ? `*${title}` : title}
						</Typography>

						{subtitle && (
							<Typography variant="small" css={{ marginTop: '$spacing8', color: '$neutral600' }}>
								{subtitle}
							</Typography>
						)}
					</Box>
				)}
				<Box css={{ marginLeft: 'auto' }}>{adornment && adornment}</Box>
			</Box>
		)}
		{children}
	</Box>
);

export default Card;
