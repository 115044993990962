import { AxiosResponse } from 'axios';
import { backofficeService } from '..';
import { CreateCorporateBusinessPayload, CreateCorporateBusinessData } from './types';

const createCorporateBusiness = async (data: CreateCorporateBusinessPayload): Promise<CreateCorporateBusinessData> => {
	const { data: response } = await backofficeService.post<
		CreateCorporateBusinessPayload,
		AxiosResponse<CreateCorporateBusinessData>
	>(`/v1/business/kyc/corporate`, {
		...data,
	});
	return response;
};
export { createCorporateBusiness };
