import { useRef, useState, useMemo, useCallback, useEffect, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { CompanyForm, PersonRepresentative } from '../CompanyData/types';
import {
	Box,
	Button,
	Checkbox,
	SelectField,
	SelectItem,
	Separator,
	TextField,
	Toast,
	Typography,
	unmaskString,
} from '@maistodos/design-system-web';
import { useNavigate } from 'react-router-dom';
import { ToastRef } from '@maistodos/design-system-web/types/components/Feedback/Toast/types';
import { createCorporateBusiness } from '../../../../../services/createCorporateBusiness';
import { CreateCorporateBusinessPayload } from '../../../../../services/createCorporateBusiness/types';
import Icon from '../../../../../components/Icon';
import { InternalBusinessType } from '../../../../Initial/screens/types';
import cep from 'cep-promise';
import Card from '../../../../../components/Card';
import { BoxGroup } from './style';
import { validator } from '../../../../../utils/validator';
import { ocupationsByBankly } from '../../../../../services/ocupations';
import { declaredIncomeOptions, memberQualificationOptions } from '../../../constants';
import { useEffectMixPanelSendEvent } from '../../../../../libs/mixpanel';

const LegalRepresentant = () => {
	const navigate = useNavigate();
	const { formState, watch, register, setValue, setError, reset } = useFormContext<CompanyForm>();
	const { isValid, errors } = formState;
	const refToastError = useRef<ToastRef | null>(null);
	const refToastSuccess = useRef<ToastRef | null>(null);
	const [loadingRetool, setLoadingRetool] = useState<boolean>(false);
	const [withNumber, setWithNumber] = useState<boolean>(true);
	const [valueSelect, setValueSelect] = useState<string>();
	const [isLoadingCepPromise, setisLoadingCepPromise] = useState<boolean>(false);
	const [isCepPromiseSuccess, setIsCepPromiseSuccess] = useState<boolean>(false);

	const ownersBusiness = watch('owners_business');
	const zipCode = watch(`legal_representatives.0.address.zip_code`);
	const pepLevel = watch(`legal_representatives.0.pep.level`);
	const viewAddress = isCepPromiseSuccess && zipCode?.length === 9;

	const ownersBusinessPersonRepresentatives: PersonRepresentative[] = useMemo(() => {
		let legalRepresentatives: PersonRepresentative[] = [];

		if (ownersBusiness) {
			ownersBusiness.forEach((owner) => {
				legalRepresentatives = [...legalRepresentatives, ...owner.legal_representatives];
			});
		}

		return legalRepresentatives;
	}, [ownersBusiness]);

	const handleZipCode = async (): Promise<void> => {
		setIsCepPromiseSuccess(false);
		setisLoadingCepPromise(true);
		try {
			const address = await cep(zipCode);
			if (!valueSelect || valueSelect === 'reset') {
				setValue(`legal_representatives.0.address.address_line`, address.street);
				setValue(`legal_representatives.0.address.neighborhood`, address.neighborhood);
			}

			setValue(`legal_representatives.0.address.city`, address.city);
			setValue(`legal_representatives.0.address.state`, address.state);
			setValue(`legal_representatives.0.address.country`, 'BR');
			setIsCepPromiseSuccess(true);
		} catch {
			setIsCepPromiseSuccess(false);
			setError(`legal_representatives.0.address.zip_code`, { type: 'required', message: 'CEP inválido.' });
			setValue(`legal_representatives.0.address.address_line`, '');
			setValue(`legal_representatives.0.address.state`, '');
			setValue(`legal_representatives.0.address.city`, '');
			setValue(`legal_representatives.0.address.neighborhood`, '');
			setValue(`legal_representatives.0.address.building_number`, '');
			setValue(`legal_representatives.0.address.complement`, '');
			setWithNumber(true);
		} finally {
			setisLoadingCepPromise(false);
		}
	};

	const handleExistentOwnerPersonal = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		setValueSelect(value);

		if (value === 'reset') {
			setValue(`legal_representatives.0.register_name`, '');
			setValue(`legal_representatives.0.social_name`, '');
			setValue(`legal_representatives.0.document_number`, '');
			setValue(`legal_representatives.0.mother_name`, '');
			setValue(`legal_representatives.0.email`, '');
			setValue(`legal_representatives.0.birth_date`, '');
			setValue(`legal_representatives.0.declared_income`, '');
			setValue(`legal_representatives.0.phone.number`, '');
			setValue(`legal_representatives.0.pep.level`, '');
			setValue(`legal_representatives.0.occupation`, '');
			setValue(`legal_representatives.0.member_qualification`, '');
			setValue(`legal_representatives.0.address`, {
				zip_code: '',
				address_line: '',
				building_number: '',
				complement: '',
				neighborhood: '',
				city: '',
				state: '',
				country: '',
			});
			setWithNumber(true);
			return;
		}

		const { owner } = JSON.parse(value);
		setValue(`legal_representatives.0.register_name`, owner.register_name);
		setValue(`legal_representatives.0.social_name`, owner.social_name);
		setValue(`legal_representatives.0.document_number`, owner.document_number);
		setValue(`legal_representatives.0.mother_name`, owner.mother_name);
		setValue(`legal_representatives.0.email`, owner.email);
		setValue(`legal_representatives.0.birth_date`, owner.birth_date);
		setValue(`legal_representatives.0.declared_income`, owner.declared_income);
		setValue(`legal_representatives.0.phone.number`, owner.phone.number);
		setValue(`legal_representatives.0.pep.level`, owner.pep.level);
		setValue(`legal_representatives.0.occupation`, owner.occupation);
		setValue(`legal_representatives.0.address`, owner.address);
	};

	const handleNoNumber = useCallback(() => {
		setWithNumber((prev) => {
			const value = !prev;

			if (value) setValue(`legal_representatives.0.address.building_number`, '', { shouldValidate: true });
			if (!value) setValue(`legal_representatives.0.address.building_number`, 'S/N', { shouldValidate: true });

			return !prev;
		});
	}, []);

	const formattedValuesRetool = (): CreateCorporateBusinessPayload => {
		const formattedOwnersBusiness: CreateCorporateBusinessPayload['owners_business'] =
			watch()?.owners_business?.map((owner) => {
				return {
					document_number: unmaskString(owner.document_number),
					address: {
						...owner.business_address,
						zip_code: unmaskString(owner.business_address.zip_code),
					},
					declared_annual_billing: owner.declared_annual_billing,
					email: owner.email,
					name: owner.business_name,
					participation_percentage: owner.participation_percentage,
					phone: {
						...owner.phone,
						number: unmaskString(owner.phone.number),
						country_code: '55',
					},
					trading_name: owner.trading_name,
					type: owner.business_type,
					legal_representatives: owner.legal_representatives.map((legal) => {
						const formattedBirthDate = legal.birth_date?.split('/').reverse().join('-');

						return {
							...legal,
							document_number: unmaskString(legal.document_number),
							birth_date: formattedBirthDate,
							address: {
								...legal.address,
								zip_code: unmaskString(legal.address.zip_code),
							},
							phone: {
								...legal.phone,
								country_code: '55',
								number: unmaskString(legal.phone.number),
							},
							declared_income: legal.declared_income,
						};
					}),
				};
			}) || [];

		const formattedOwnersPersonal: CreateCorporateBusinessPayload['owners_personal'] =
			watch()?.owners_personal?.map((owner) => {
				const formattedBirthDate = owner.birth_date?.split('/').reverse().join('-');

				return {
					...owner,
					birth_date: formattedBirthDate,
					document_number: unmaskString(owner.document_number),
					address: {
						...owner.address,
						zip_code: unmaskString(owner.address.zip_code),
					},
					phone: {
						...owner.phone,
						country_code: '55',
						number: unmaskString(owner.phone.number),
					},
					declared_income: owner.declared_income,
				};
			}) || [];

		const formattedLegalRepresentatives: CreateCorporateBusinessPayload['legal_representatives'] =
			watch()?.legal_representatives?.map((legalRepresentative) => {
				const formattedBirthDate = legalRepresentative.birth_date?.split('/').reverse().join('-');

				return {
					...legalRepresentative,
					birth_date: formattedBirthDate,
					document_number: unmaskString(legalRepresentative.document_number),
					address: {
						...legalRepresentative.address,
						zip_code: unmaskString(legalRepresentative.address.zip_code),
					},
					phone: {
						...legalRepresentative.phone,
						country_code: '55',
						number: unmaskString(legalRepresentative.phone.number),
					},
					declared_income: legalRepresentative.declared_income,
				};
			}) || [];

		let formattedDocumentation: CreateCorporateBusinessPayload['documentation'] = [];

		if (watch().documentation) {
			formattedDocumentation = Object.entries(watch().documentation)?.map(([key, value]) => {
				return {
					file: value,
					type: key as CreateCorporateBusinessPayload['documentation'][0]['type'],
				};
			});
		}

		const formattedOpeningDate = watch()?.opening_date?.split('/').reverse().join('-');

		return {
			cnae_code: unmaskString(watch()?.cnae_code),
			documentation: formattedDocumentation,
			email: watch()?.business_email,
			legal_nature: watch()?.legal_nature,
			name: watch()?.business_name,
			size: watch()?.business_size,
			trading_name: watch()?.trading_name,
			type: watch()?.business_type,
			phone: {
				country_code: '55',
				number: unmaskString(watch()?.phone?.number),
			},
			owners_personal: formattedOwnersPersonal,
			legal_representatives: formattedLegalRepresentatives,
			owners_business: formattedOwnersBusiness,
			document_number: unmaskString(watch()?.document_number),
			opening_date: formattedOpeningDate,
			address: {
				...watch()?.business_address,
				zip_code: unmaskString(watch()?.business_address?.zip_code),
			},
			declared_annual_billing: watch()?.declared_annual_billing,
			internal_business_type: sessionStorage.getItem('INTERNAL_BUSINESS_TYPE') as InternalBusinessType,
		};
	};

	const handleSubmitRetool = async (): Promise<void> => {
		try {
			setLoadingRetool(true);
			await createCorporateBusiness(formattedValuesRetool());
			reset();
			navigate(`/finish`);
		} catch {
			refToastError?.current?.publish();
		} finally {
			setLoadingRetool(false);
		}
	};

	const disabled = useMemo(() => !isValid || !viewAddress || !pepLevel, [isValid, viewAddress, pepLevel]);

	useEffect(() => {
		if (zipCode?.length === 9) {
			handleZipCode();
		}
	}, [zipCode]);

	useEffectMixPanelSendEvent({
		event: 'new_account_pjotao_userdate',
	});

	return (
		<>
			<BoxGroup>
				<Typography variant={'h4'} weight={'semibold'} color={'$neutral600'} css={{ marginBottom: '$spacing24' }}>
					Representante Legal
				</Typography>
				<Card title="Preencher com usuário já existente">
					<SelectField placeholder="Selecione o usuário" onChange={handleExistentOwnerPersonal}>
						<SelectItem value="reset">–</SelectItem>
						{watch('owners_personal')?.map((owner, index) => (
							<SelectItem key={`personal-${index}`} value={JSON.stringify({ owner })}>
								{owner.register_name}
							</SelectItem>
						))}
						{ownersBusinessPersonRepresentatives?.map((owner, index) => (
							<SelectItem key={`legal_representative-${index}`} value={JSON.stringify({ owner })}>
								{owner.register_name}
							</SelectItem>
						))}
					</SelectField>
				</Card>
				<Card title="Nome de registro, como no documento (RG ou CNH):" subtitle="Conforme documento legal" required>
					<TextField
						label="Nome completo"
						type="text"
						maxLength={250}
						value={watch(`legal_representatives.0.register_name`)}
						error={!!errors?.legal_representatives?.[0]?.register_name}
						helperText={errors.legal_representatives?.[0]?.register_name?.message}
						{...register(`legal_representatives.0.register_name`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 3,
								message: 'O campo não foi preenchido corretamente.',
							},
						})}
					/>
				</Card>
				<Card title="Nome de preferência (como podemos nos referir a você):">
					<TextField
						label="Nome de preferência"
						type="text"
						maxLength={250}
						value={watch(`legal_representatives.0.social_name`)}
						error={!!errors?.legal_representatives?.[0]?.social_name}
						helperText={errors.legal_representatives?.[0]?.social_name?.message}
						{...register(`legal_representatives.0.social_name`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
						})}
					/>
				</Card>
				<Card title="CPF (digite apenas os números):" required>
					<TextField
						label="CPF"
						type="text"
						mask={'ddd.ddd.ddd-dd'}
						value={watch(`legal_representatives.0.document_number`)}
						error={!!errors?.legal_representatives?.[0]?.document_number}
						helperText={errors.legal_representatives?.[0]?.document_number?.message}
						{...register(`legal_representatives.0.document_number`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 14,
								message: 'O campo não foi preenchido corretamente.',
							},
							validate: (e) => validator.isValidCPF(e),
						})}
					/>
				</Card>
				<Card title="Nome da mãe (insira o nome completo como no documento):" required>
					<TextField
						label="Nome da mãe"
						type="text"
						maxLength={250}
						value={watch(`legal_representatives.0.mother_name`)}
						error={!!errors?.legal_representatives?.[0]?.mother_name}
						helperText={errors.legal_representatives?.[0]?.mother_name?.message}
						{...register(`legal_representatives.0.mother_name`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 6,
								message: 'O campo não foi preenchido corretamente.',
							},
						})}
					/>
				</Card>
				<Card title="E-mail" required>
					<TextField
						label="E-mail"
						type="text"
						maxLength={255}
						value={watch(`legal_representatives.0.email`)}
						error={!!errors?.legal_representatives?.[0]?.email}
						helperText={errors.legal_representatives?.[0]?.email?.message}
						{...register(`legal_representatives.0.email`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'E-mail inválido.',
							},
						})}
					/>
				</Card>
				<Card title="Principal número celular para contato com DDD:" required>
					<TextField
						label="Telefone"
						type="text"
						mask="(dd) ddddd-dddd"
						value={watch(`legal_representatives.0.phone.number`)}
						error={!!errors?.legal_representatives?.[0]?.phone?.number}
						helperText={errors.legal_representatives?.[0]?.phone?.number?.message}
						{...register(`legal_representatives.0.phone.number`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 15,
								message: 'O preenchimento é obrigatório.',
							},
							validate: (e) => validator.isValidPhone(unmaskString(e)) || 'Número de celular inválido.',
						})}
					/>
				</Card>
				<Card
					title="Data de nascimento. Siga o modelo de preenchimento DD/MM/AAAA:"
					subtitle="O representante legal deve ser maior de 18 anos."
					required
				>
					<TextField
						label="Data de nascimento"
						type="text"
						mask="dd/dd/dddd"
						value={watch(`legal_representatives.0.birth_date`)}
						error={!!errors?.legal_representatives?.[0]?.birth_date}
						helperText={errors.legal_representatives?.[0]?.birth_date?.message}
						{...register(`legal_representatives.0.birth_date`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 10,
								message: 'O campo não foi preenchido corretamente.',
							},
							validate: (e) => validator.isValidDate(e, 'birthDate'),
						})}
					/>
				</Card>
				<Card title="Faixa de renda declarada" required>
					<SelectField
						value={watch(`legal_representatives.0.declared_income`)}
						placeholder="Selecione a faixa de renda declarada"
						error={!!errors?.legal_representatives?.[0]?.declared_income}
						helperText={errors.legal_representatives?.[0]?.declared_income?.message}
						{...register(`legal_representatives.0.declared_income`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
						})}
						onChange={(e) =>
							setValue(`legal_representatives.0.declared_income`, e.target.value, { shouldValidate: true })
						}
					>
						{declaredIncomeOptions.map((option) => (
							<SelectItem value={option.value} key={option.value}>
								{option.label}
							</SelectItem>
						))}
					</SelectField>
				</Card>
				<Card title="Pessoa politicamente exposta" required>
					<Box css={{ marginBottom: '$spacing24' }}>
						<Checkbox
							name={`legal_representatives.0.pep.level`}
							label="Não sou uma pessoa politicamente exposta"
							value="none"
							onChange={() => setValue(`legal_representatives.0.pep.level`, 'none')}
							checked={pepLevel === 'none'}
						/>
					</Box>
					<Box css={{ marginBottom: '$spacing24' }}>
						<Checkbox
							name={`legal_representatives.0.pep.level`}
							label="Sou uma pessoa politicamente exposta"
							value="self"
							onChange={() => setValue(`legal_representatives.0.pep.level`, 'self')}
							checked={pepLevel === 'self'}
						/>
					</Box>
					<Box>
						<Checkbox
							name={`legal_representatives.0.pep.level`}
							label="Possuo relação com pessoa politicamente exposta"
							value="related"
							onChange={() => setValue(`legal_representatives.0.pep.level`, 'related')}
							checked={pepLevel === 'related'}
						/>
					</Box>
				</Card>
				<Card title="Ocupação principal" required>
					<SelectField
						placeholder="Selecione a ocupação principal"
						error={!!errors?.legal_representatives?.[0]?.occupation}
						helperText={errors.legal_representatives?.[0]?.occupation?.message}
						value={watch(`legal_representatives.0.occupation`)}
						{...register(`legal_representatives.0.occupation`, {
							required: {
								value: true,
								message: 'É necessário informar a ocupação principal.',
							},
						})}
						onChange={(e) => setValue(`legal_representatives.0.occupation`, e.target.value, { shouldValidate: true })}
					>
						{ocupationsByBankly().map((item) => (
							<SelectItem value={item.id} key={item.id}>
								{item.description}
							</SelectItem>
						))}
					</SelectField>
				</Card>
				<Card title="Qualificação da pessoa física" required>
					<SelectField
						placeholder="Selecione a qualificação da pessoa física"
						error={!!errors?.legal_representatives?.[0]?.member_qualification}
						helperText={errors.legal_representatives?.[0]?.member_qualification?.message}
						value={watch(`legal_representatives.0.member_qualification`)}
						{...register(`legal_representatives.0.member_qualification`, {
							required: {
								value: true,
								message: 'É necessário informar a qualificação da pessoa física.',
							},
						})}
						onChange={(e) =>
							setValue(`legal_representatives.0.member_qualification`, e.target.value, { shouldValidate: true })
						}
					>
						{memberQualificationOptions.map((option) => (
							<SelectItem value={option.value} key={option.value}>
								{option.label}
							</SelectItem>
						))}
					</SelectField>
				</Card>
				<Card
					title="Endereço completo do representante legal:"
					subtitle="Informe o endereço completo do representante legal: Rua, número, complemento (se houver), bairro, cidade e estado."
					required
				>
					<Box
						css={{
							width: '50%',
						}}
					>
						<TextField
							label="CEP"
							type="text"
							mask={'ddddd-ddd'}
							value={zipCode}
							error={!!errors?.legal_representatives?.[0]?.address?.zip_code}
							helperText={errors.legal_representatives?.[0]?.address?.zip_code?.message}
							endAdornment={isLoadingCepPromise && <Icon name="Refresh" fontSize="$large" />}
							{...register(`legal_representatives.0.address.zip_code`, {
								required: {
									value: true,
									message: 'É necessário preencher com CEP.',
								},
							})}
						/>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'column',
							width: '50%',
							marginTop: '$spacing24',
						}}
					>
						<TextField
							label="Endereço"
							maxLength={250}
							type="text"
							value={watch(`legal_representatives.0.address.address_line`)}
							{...register(`legal_representatives.0.address.address_line`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
						/>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
							<TextField
								label="Número"
								mask="ddddddddd"
								type="text"
								value={watch(`legal_representatives.0.address.building_number`)}
								{...register(`legal_representatives.0.address.building_number`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
								disabled={!withNumber}
							/>
						</Box>
						<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
							<TextField
								label="Complemento"
								maxLength={150}
								type="text"
								value={watch(`legal_representatives.0.address.complement`)}
								{...register(`legal_representatives.0.address.complement`)}
							/>
						</Box>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Box css={{ width: '50%' }}>
							<TextField
								label="Bairro"
								maxLength={200}
								type="text"
								value={watch(`legal_representatives.0.address.neighborhood`)}
								{...register(`legal_representatives.0.address.neighborhood`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
							/>
						</Box>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Box
							css={{
								display: viewAddress ? 'flex' : 'none',
								marginTop: '$spacing24',
								marginBottom: '$spacing24',
								width: '50%',
							}}
						>
							<TextField
								label="Cidade"
								type="text"
								value={watch(`legal_representatives.0.address.city`)}
								{...register(`legal_representatives.0.address.city`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
								disabled
							/>
						</Box>
						<Box
							css={{
								display: viewAddress ? 'flex' : 'none',
								marginTop: '$spacing24',
								marginBottom: '$spacing24',
								marginLeft: '$spacing16',
								width: '50%',
							}}
						>
							<TextField
								label="Estado"
								value={watch(`legal_representatives.0.address.state`)}
								type="text"
								{...register(`legal_representatives.0.address.state`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
								disabled
							/>
						</Box>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Checkbox
							name={`legal_representatives_0_without_number`}
							label="Endereço sem número?"
							value="false"
							onChange={handleNoNumber}
							checked={!withNumber}
						/>
					</Box>
				</Card>
			</BoxGroup>
			<Box
				css={{
					display: 'flex',
					flexDirection: 'column',
					gap: '$spacing12',
				}}
			>
				<Separator css={{ marginTop: '$spacing16', marginBottom: '$spacing16', alignSelf: 'center' }} />
				<Button onClick={handleSubmitRetool} disabled={disabled} loading={loadingRetool}>
					Enviar
				</Button>
			</Box>
			<Toast
				ref={refToastSuccess}
				direction="top-right"
				icon={<Icon name="Info" color="$currentColor" />}
				title="O código foi copiado!"
				type="success"
			/>
			<Toast
				ref={refToastError}
				direction="top-right"
				icon={<Icon name="Close" color="$currentColor" />}
				title="Erro ao enviar dados, tente novamente mais tarde."
				type="critical"
			/>
		</>
	);
};

export default LegalRepresentant;
