import { useMemo } from 'react';

import * as Icons from '@maistodos/core-icons';
import { Box } from '@maistodos/design-system-web';

import type { IconProps } from './types';

const Icon = ({ color = `$neutral500`, fontSize = '$h3', name, size, rounded = false }: IconProps) => {
	const renderIcon = useMemo(() => {
		const Icon = Icons[name];
		return <Icon color="inherit" fontSize="inherit" />;
	}, [name]);

	return (
		<>
			{rounded ? (
				<Box
					css={{
						width: 32,
						height: 32,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '$circle',
						backgroundColor: '$neutral50',
					}}
				>
					<Box css={{ color: color, fill: color, fontSize: 24 }}>{renderIcon}</Box>
				</Box>
			) : (
				<Box css={{ color: color, fill: color, fontSize: size ?? fontSize }}>{renderIcon}</Box>
			)}
		</>
	);
};

export default Icon;
