import { Ocupation } from './types';

export const ocupationsByBankly = (): Array<Ocupation> => {
	return [
		{ id: 'OCP0001', description: 'Administrador Iniciativa Privada' },
		{ id: 'OCP0002', description: 'Administrador Público' },
		{ id: 'OCP0003', description: 'Advogado' },
		{ id: 'OCP0004', description: 'Agricultor' },
		{ id: 'OCP0005', description: 'Agrônomo' },
		{ id: 'OCP0006', description: 'Agropecuarista' },
		{ id: 'OCP0007', description: 'Analista e Desenvolvedor de Sistemas' },
		{ id: 'OCP0008', description: 'Animador' },
		{ id: 'OCP0009', description: 'Arqueólogo' },
		{ id: 'OCP0010', description: 'Arquiteto' },
		{ id: 'OCP0011', description: 'Arquivologista' },
		{ id: 'OCP0012', description: 'Artes e Design' },
		{ id: 'OCP0013', description: 'Artista Plástico' },
		{ id: 'OCP0014', description: 'Astrônomo' },
		{ id: 'OCP0015', description: 'Ator' },
		{ id: 'OCP0016', description: 'Biblioteconomista' },
		{ id: 'OCP0017', description: 'Biomédico' },
		{ id: 'OCP0018', description: 'Bioquímico' },
		{ id: 'OCP0019', description: 'Biotecnólogo' },
		{ id: 'OCP0020', description: 'Chefe de Cozinha' },
		{ id: 'OCP0021', description: 'Ciências Sociais e Humanas' },
		{ id: 'OCP0022', description: 'Cientista da Computação' },
		{ id: 'OCP0023', description: 'Construtor Civil' },
		{ id: 'OCP0024', description: 'Construtor Naval' },
		{ id: 'OCP0025', description: 'Contabilista' },
		{ id: 'OCP0026', description: 'Cooperativista' },
		{ id: 'OCP0027', description: 'Dançarino' },
		{ id: 'OCP0028', description: 'Dentista' },
		{ id: 'OCP0029', description: 'Designer' },
		{ id: 'OCP0030', description: 'Designer de Games' },
		{ id: 'OCP0031', description: 'Designer de Interiores' },
		{ id: 'OCP0032', description: 'Designer de Moda' },
		{ id: 'OCP0033', description: 'Ecologista' },
		{ id: 'OCP0034', description: 'Economista' },
		{ id: 'OCP0035', description: 'Educador Físico' },
		{ id: 'OCP0036', description: 'Educomunicador' },
		{ id: 'OCP0037', description: 'Enfermeiro' },
		{ id: 'OCP0038', description: 'Engenheiro' },
		{ id: 'OCP0039', description: 'Especialista em Comércio Exterior' },
		{ id: 'OCP0040', description: 'Estatístico' },
		{ id: 'OCP0041', description: 'Esteticista' },
		{ id: 'OCP0042', description: 'Farmacêutico' },
		{ id: 'OCP0043', description: 'Filósofo' },
		{ id: 'OCP0044', description: 'Físico' },
		{ id: 'OCP0045', description: 'Fisioterapeuta' },
		{ id: 'OCP0046', description: 'Fonoaudiólogo' },
		{ id: 'OCP0047', description: 'Fotógrafo' },
		{ id: 'OCP0048', description: 'Geofísico' },
		{ id: 'OCP0049', description: 'Geógrafo' },
		{ id: 'OCP0050', description: 'Geólogo' },
		{ id: 'OCP0051', description: 'Gerente Comercial' },
		{ id: 'OCP0052', description: 'Gerontólogo' },
		{ id: 'OCP0053', description: 'Gestor Ambiental' },
		{ id: 'OCP0054', description: 'Gestor da Qualidade' },
		{ id: 'OCP0055', description: 'Gestor da Tecnologia da Informação' },
		{ id: 'OCP0056', description: 'Gestor de Recursos Humanos' },
		{ id: 'OCP0057', description: 'Gestor de Turismo' },
		{ id: 'OCP0058', description: 'Gestor em Saúde' },
		{ id: 'OCP0059', description: 'Gestor Hospitalar' },
		{ id: 'OCP0060', description: 'Gestor Público' },
		{ id: 'OCP0061', description: 'Historiador' },
		{ id: 'OCP0062', description: 'Historiador da Arte' },
		{ id: 'OCP0063', description: 'Hoteleiro' },
		{ id: 'OCP0064', description: 'Jornalista' },
		{ id: 'OCP0065', description: 'Linguista' },
		{ id: 'OCP0066', description: 'Matemático' },
		{ id: 'OCP0067', description: 'Médico' },
		{ id: 'OCP0068', description: 'Meteorologista' },
		{ id: 'OCP0069', description: 'Minerador' },
		{ id: 'OCP0070', description: 'Museologista' },
		{ id: 'OCP0071', description: 'Músico' },
		{ id: 'OCP0072', description: 'Musicoterapeuta' },
		{ id: 'OCP0073', description: 'Nanotecnólogo' },
		{ id: 'OCP0074', description: 'Nutricionista' },
		{ id: 'OCP0075', description: 'Oceanógrafo' },
		{ id: 'OCP0076', description: 'Pedagogo' },
		{ id: 'OCP0077', description: 'Piloto de Avião' },
		{ id: 'OCP0078', description: 'Produtor Audiovisual' },
		{ id: 'OCP0079', description: 'Produtor Cênico' },
		{ id: 'OCP0080', description: 'Produtor Cultural' },
		{ id: 'OCP0081', description: 'Produtor Fonográfico' },
		{ id: 'OCP0082', description: 'Professor' },
		{ id: 'OCP0083', description: 'Psicólogo' },
		{ id: 'OCP0084', description: 'Psicopedagogo' },
		{ id: 'OCP0085', description: 'Publicitário' },
		{ id: 'OCP0086', description: 'Químico' },
		{ id: 'OCP0087', description: 'Radialista' },
		{ id: 'OCP0088', description: 'Radiologista' },
		{ id: 'OCP0089', description: 'Relações Internacionais' },
		{ id: 'OCP0090', description: 'Relações Públicas' },
		{ id: 'OCP0091', description: 'Saúde e Bem-estar' },
		{ id: 'OCP0092', description: 'Secretária' },
		{ id: 'OCP0093', description: 'Silvicultor' },
		{ id: 'OCP0094', description: 'Sociólogo' },
		{ id: 'OCP0095', description: 'Teólogo' },
		{ id: 'OCP0096', description: 'Terapeuta Ocupacional' },
		{ id: 'OCP0097', description: 'Tradutor e Intérprete' },
		{ id: 'OCP0098', description: 'Turismólogo' },
		{ id: 'OCP0099', description: 'Veterinário' },
		{ id: 'OCP0100', description: 'Zootecnólogo' },
		{ id: 'OCP0101', description: 'Autônomo' },
		{ id: 'OCP0102', description: 'Aposentado' },
		{ id: 'OCP0103', description: 'Sem Ocupação' },
		{ id: 'OCP0104', description: 'Estudante' },
		{ id: 'OCP0105', description: 'Outros' },
	];
};
