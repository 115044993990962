export const annualBillingOptions = [
	{
		label: 'Empresa Isenta',
		value: 'exempt_company',
	},
	{
		label: 'Empresa Inativa',
		value: 'inactive_company',
	},
	{
		label: 'Até R$ 50.000,00',
		value: 'up_to_fifty_thousand',
	},
	{
		label: 'Mais de R$ 50.000,00 até R$ 100.000,00',
		value: 'more_than_fifty_thousand_up_to_one_hundred_thousand',
	},
	{
		label: 'Mais de R$ 100.000,00 até R$ 250.000,00',
		value: 'more_than_one_hundred_thousand_up_to_two_hundred_and_fifty_thousand',
	},
	{
		label: 'Mais de R$ 250.000,00 até R$ 500.000,00',
		value: 'more_than_two_hundred_and_fifty_thousand_up_to_five_hundred_thousand',
	},
	{
		label: 'Mais de R$ 500.000,00 até R$ 1.000.000,00',
		value: 'more_than_five_hundred_thousand_up_to_one_million',
	},
	{
		label: 'Mais de R$ 1.000.000,00 até R$ 2.500.000,00',
		value: 'more_than_one_million_up_to_two_million_and_five_hundred_thousand',
	},
	{
		label: 'Mais de R$ 2.500.000,00 até R$ 5.000.000,00',
		value: 'more_than_two_million_and_five_hundred_thousand_up_to_five_million',
	},
	{
		label: 'Mais de R$ 5.000.000,00 até R$ 10.000.000,00',
		value: 'more_than_five_million_up_to_ten_million',
	},
	{
		label: 'Mais de R$ 10.000.000,00 até R$ 25.000.000,00',
		value: 'more_than_ten_million_up_to_twenty_five_million',
	},
	{
		label: 'Mais de R$ 25.000.000,00 até R$ 50.000.000,00',
		value: 'more_than_twenty_five_million_up_to_fifty_million',
	},
	{
		label: 'Mais de R$ 50.000.000,00 até R$ 100.000.000,00',
		value: 'more_than_fifty_million_up_to_one_hundred_million',
	},
	{
		label: 'Mais de R$ 100.000.000,00 até R$ 250.000.000,00',
		value: 'more_than_one_hundred_million_up_to_two_hundred_and_fifty_million',
	},
	{
		label: 'Mais de R$ 250.000.000,00 até R$ 500.000.000,00',
		value: 'more_than_two_hundred_and_fifty_million_up_to_five_hundred_million',
	},
	{
		label: 'Mais de R$ 500.000.000,00',
		value: 'more_than_five_hundred_million',
	},
];

export const declaredIncomeOptions = [
	{
		label: 'Menos de R$ 1.000,00',
		value: 'less_than_one_thousand',
	},
	{
		label: 'De R$ 1.000,00 a R$ 1.999,99',
		value: 'from_one_thousand_to_two_thousand',
	},
	{
		label: 'De R$ 2.000,00 a R$ 2.999,99',
		value: 'from_two_thousand_to_three_thousand',
	},
	{
		label: 'De R$ 3.000,00 a R$ 4.999,99',
		value: 'from_three_thousand_to_five_thousand',
	},
	{
		label: 'De R$ 5.000,00 a R$ 9.999,99',
		value: 'from_five_thousand_to_ten_thousand',
	},
	{
		label: 'De R$ 10.000,00 a R$ 20.000,00',
		value: 'from_ten_thousand_to_twenty_thousand',
	},
	{
		label: 'Mais de R$ 20.000,00',
		value: 'over_twenty_thousand',
	},
];

export const memberQualificationOptions = [
	{ label: 'Administrador', value: '05' },
	{ label: 'Diretor', value: '10' },
	{ label: 'Cônsul', value: '39' },
	{ label: 'Cônsul Honorário', value: '60' },
	{ label: 'Fundador', value: '54' },
	{ label: 'Presidente', value: '16' },
	{ label: 'Procurador', value: '17' },
	{ label: 'Sócio-Administrador', value: '49' },
	{ label: 'Sócio Comanditado', value: '24' },
	{ label: 'Tabelião', value: '32' },
	{ label: 'Oficial de registro', value: '42' },
	{ label: 'Ministro de Estado das Relações Exteriores', value: '46' },
	{ label: 'Titular Pessoa Física Residente ou Domiciliado no Brasil', value: '65' },
];
