import { createGlobalStyle } from 'styled-components';

export const globalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  body {
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 0px;

    background-position: bottom !important;
    background-repeat: no-repeat;
    background-attachment: fixed !important;
    opacity: 1;
    -webkit-font-smoothing: antialiased;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }
  }

  #root {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;

    .App {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }
`;
