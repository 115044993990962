import { useEffect } from 'react';

import MixPanel from '../../MixPanel';

import type { UseEffectMixPanelSendEventProps } from './types';

const useEffectMixPanelSendEvent = ({ event, deps = [], condition = true }: UseEffectMixPanelSendEventProps) => {
	useEffect(() => {
		if (condition) {
			MixPanel.sendEvent(event);
		}
	}, deps);
};
export { useEffectMixPanelSendEvent };
