import { type ChangeEvent, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Card from '../../../../../components/Card';
import {
	Box,
	Button,
	Checkbox,
	SelectField,
	SelectItem,
	TextField,
	Toast,
	Typography,
	unmaskString,
} from '@maistodos/design-system-web';
import { useFormContext } from 'react-hook-form';
import cep from 'cep-promise';
import { format } from 'date-fns';
import { CompanyForm } from './types';
import { validator } from '../../../../../utils/validator';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastRef } from '@maistodos/design-system-web/types/components/Feedback/Toast/types';
import { EMPTY_LAST_CONTRACT_BASE64, MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from './constants';
import { converter } from '../../../../../utils/converterFile';
import Icon from '../../../../../components/Icon';
import { annualBillingOptions } from '../../../constants';
import { useEffectMixPanelSendEvent } from '../../../../../libs/mixpanel';

const CompanyData = () => {
	const { register, watch, setValue, formState, setError } = useFormContext<CompanyForm>();
	const { errors, isValid } = formState;
	const navigate = useNavigate();
	const { companySize } = useParams();

	const inputArticlesOfIncorporationRef = useRef<HTMLInputElement>(null);
	const inputLastContractChangeRef = useRef<HTMLInputElement>(null);
	const refToast = useRef<ToastRef | null>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [articleOfIncorporation, setArticleOfIncorporation] = useState<File>();
	const [lastContractChange, setLastContractChange] = useState<File>();
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [withNumber, setWithNumber] = useState<boolean>(true);
	const [isLoadingCepPromise, setisLoadingCepPromise] = useState<boolean>(false);
	const [isCepPromiseSuccess, setIsCepPromiseSuccess] = useState<boolean>(false);
	const [hasLastContract, setHasLastContract] = useState<boolean>(false);

	const businessSize = watch('business_size') as unknown;
	const businessType = watch('business_type') as unknown;
	const legalNature = watch('legal_nature') as unknown;
	const zipCode = watch('business_address.zip_code') as unknown as string;
	const viewAddress = isCepPromiseSuccess && zipCode?.length === 9;

	const isFormValid = useMemo(
		() =>
			!isValid ||
			!viewAddress ||
			!businessType ||
			!businessSize ||
			!legalNature ||
			!articleOfIncorporation ||
			(!lastContractChange && !hasLastContract),
		[
			isValid,
			viewAddress,
			businessType,
			businessSize,
			legalNature,
			articleOfIncorporation,
			lastContractChange,
			hasLastContract,
		],
	);

	const handleChange = (e: ChangeEvent<HTMLInputElement>, name: string): void => {
		const { value } = e.target;
		switch (name) {
			case 'business_size':
				setValue(name, value);
				break;
			case 'business_type':
				setValue(name, value);
				break;
			case 'opening_date':
				setValue('opening_date', format(new Date(value), 'yyyy-MM-dd'));
				break;
			case 'legal_nature':
				setValue(name, value);
				break;
			default:
				break;
		}
	};

	const handleZipCode = useCallback(async (): Promise<void> => {
		setIsCepPromiseSuccess(false);
		setisLoadingCepPromise(true);
		try {
			const address = await cep(zipCode);
			setValue('business_address.address_line', address.street);
			setValue('business_address.state', address.state);
			setValue('business_address.city', address.city);
			setValue('business_address.neighborhood', address.neighborhood);
			setValue('business_address.country', 'BR');
			setIsCepPromiseSuccess(true);
		} catch {
			setIsCepPromiseSuccess(false);
			setError('business_address.zip_code', { type: 'required', message: 'CEP inválido.' });
			setValue('business_address.address_line', '');
			setValue('business_address.state', '');
			setValue('business_address.city', '');
			setValue('business_address.neighborhood', '');
			setValue('business_address.building_number', '');
			setValue('business_address.complement', '');
			setWithNumber(true);
		} finally {
			setisLoadingCepPromise(false);
		}
	}, [cep, zipCode]);

	const handleNoNumber = useCallback(() => {
		setWithNumber((prev) => {
			const value = !prev;

			if (value) setValue('business_address.building_number', '', { shouldValidate: true });
			if (!value) setValue('business_address.building_number', 'S/N', { shouldValidate: true });

			return !prev;
		});
	}, []);

	const handleSelectFile = (e: ChangeEvent<HTMLInputElement>): void => {
		const { files, name } = e.target;

		if (files && files.length) {
			const selectedFile = files[0];

			if (selectedFile.size > MAX_FILE_SIZE_MB) {
				setErrorMessage('O arquivo selecionado excede o tamanho máximo permitido.');
				refToast?.current?.publish();
				return;
			}

			switch (name) {
				case 'articles_of_incorporation':
					setArticleOfIncorporation(files[0]);
					break;
				case 'last_contract_change':
					setLastContractChange(files[0]);
					break;
				default:
					break;
			}
		}
	};

	const handleSubmit = async (): Promise<void> => {
		if (articleOfIncorporation && (lastContractChange || hasLastContract)) {
			try {
				setLoading(true);
				setValue(
					'documentation.articles_of_incorporation',
					(await converter.fileToBase64(articleOfIncorporation)).split(',')[1],
				);
				const documentationLastContract = lastContractChange
					? (await converter.fileToBase64(lastContractChange)).split(',')[1]
					: EMPTY_LAST_CONTRACT_BASE64;
				setValue('documentation.last_contract_change', documentationLastContract);
				setLoading(false);
				return navigate(`/form/${companySize}/2`);
			} catch {
				setErrorMessage('Não foi possível enviar os arquivos, tente novamente.');
				refToast?.current?.publish();
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (zipCode?.length === 9) {
			void handleZipCode();
		}
	}, [zipCode]);

	useEffectMixPanelSendEvent({
		event: 'new_account_pjotao_start',
	});

	return (
		<>
			<Box
				css={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '$spacing12',
					gap: '$spacing8',
					cursor: 'pointer',
				}}
				onClick={() => navigate(`/home`)}
			>
				<Icon name="ArrowLeft" color="$neutral600" />
				<Typography variant="h6" weight="bold" color="$neutral600">
					Preencha todos os campos para avançar
				</Typography>
			</Box>
			<Card title="Selecione o tipo da sua empresa:" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_type"
						label="LTDA - Sociedade Limitada"
						value="ltda"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'ltda'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_type"
						label="S.A - Sociedade Anônima"
						value="sa"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'sa'}
					/>
				</Box>
				<Box>
					<Checkbox
						name="business_type"
						label="TS - Terceiro Setor"
						value="ts"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'ts'}
					/>
				</Box>
			</Card>
			<Card title="Selecione o tamanho da sua empresa:" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_size"
						label="Pequena"
						value="small"
						onChange={(e) => handleChange(e, 'business_size')}
						checked={businessSize === 'small'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_size"
						label="Média"
						value="medium"
						onChange={(e) => handleChange(e, 'business_size')}
						checked={businessSize === 'medium'}
					/>
				</Box>
				<Box>
					<Checkbox
						name="business_size"
						label="Grande"
						value="large"
						onChange={(e) => handleChange(e, 'business_size')}
						checked={businessSize === 'large'}
					/>
				</Box>
			</Card>
			<Card title="A natureza jurídica da sua empresa é:" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="legal_nature"
						label="MEI - Microempreendedor Individual"
						value="mei"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'mei'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="legal_nature"
						label="EI - Empreendedor Individual"
						value="ei"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'ei'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="legal_nature"
						label="EIRELI - Empresa Individual de Responsabilidade Limitada"
						value="eireli"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'eireli'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="legal_nature"
						label="SLU - Sociedade limitada Unipessoal (LTDA apenas com um sócio)"
						value="slu"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'slu'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="legal_nature"
						label="LTDA - Sociedade Empresária Limitada"
						value="ltda"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'ltda'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="legal_nature"
						label="SA - Sociedade Anônima"
						value="sa"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'sa'}
					/>
				</Box>
				<Box>
					<Checkbox
						name="legal_nature"
						label="Outra natureza jurídica"
						value="outras"
						onChange={(e) => handleChange(e, 'legal_nature')}
						checked={legalNature === 'outras'}
					/>
				</Box>
			</Card>
			<Card title="Informe o CNPJ (digite apenas os números):" required>
				<TextField
					label="CNPJ"
					mask="dd.ddd.ddd/dddd-dd"
					type="text"
					error={!!errors?.document_number}
					helperText={errors.document_number?.message}
					value={watch('document_number')}
					{...register('document_number', {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 18,
							message: 'São necessários 14 números.',
						},
						validate: (e) => validator.isValidCNPJ(e),
					})}
				/>
			</Card>
			<Card title="Nome da empresa (razão social):" required>
				<TextField
					label="Razão social"
					type="text"
					maxLength={128}
					error={!!errors?.business_name}
					helperText={errors.business_name?.message}
					value={watch('business_name')}
					{...register('business_name', {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
				/>
			</Card>
			<Card title="Nome fantasia (nome comercial):">
				<TextField
					label="Nome fantasia"
					type="text"
					error={!!errors?.trading_name}
					helperText={errors.trading_name?.message}
					value={watch('trading_name')}
					maxLength={128}
					{...register('trading_name')}
				/>
			</Card>
			<Card title="E-mail para contato com a empresa:" required>
				<TextField
					label="E-mail"
					type="email"
					maxLength={255}
					error={!!errors?.business_email}
					helperText={errors.business_email?.message}
					value={watch('business_email')}
					{...register('business_email', {
						required: {
							value: true,
							message: 'Preenchimento obrigatório.',
						},
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'E-mail inválido.',
						},
					})}
				/>
			</Card>
			<Card title="Principal número celular para contato com DDD:" required>
				<TextField
					label="Telefone"
					type="text"
					mask="(dd) ddddd-dddd"
					value={watch(`phone.number`)}
					error={!!errors?.phone?.number}
					helperText={errors.phone?.number?.message}
					{...register(`phone.number`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 15,
							message: 'O preenchimento é obrigatório.',
						},
						validate: (e) => validator.isValidPhone(unmaskString(e)) || 'Número de celular inválido.',
					})}
				/>
			</Card>
			<Card
				title="Data da abertura da empresa, segundo consta no cartão CNPJ. Siga o modelo de preenchimento DD/MM/AAAA:"
				required
			>
				<TextField
					label="Data de abertura"
					type="text"
					mask="dd/dd/dddd"
					value={watch(`opening_date`)}
					error={!!errors?.opening_date}
					helperText={errors.opening_date?.message}
					{...register(`opening_date`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 10,
							message: 'O campo não foi preenchido corretamente.',
						},
						validate: (e) => validator.isValidDate(e, 'openingDate'),
					})}
				/>
			</Card>
			<Card
				title="Faturamento anual da empresa:"
				subtitle="Caso a empresa ainda não tenha 1 ano, inserir a estimativa."
				required
			>
				<SelectField
					placeholder="Selecione o faturamento anual da empresa"
					error={!!errors?.declared_annual_billing}
					value={watch(`declared_annual_billing`)}
					{...register(`declared_annual_billing`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
					onChange={(e) => setValue('declared_annual_billing', e.target.value, { shouldValidate: true })}
				>
					{annualBillingOptions.map((option) => (
						<SelectItem key={option.value} value={option.value}>
							{option.label}
						</SelectItem>
					))}
				</SelectField>
			</Card>
			<Card title="CNAE" required>
				<TextField
					label="CNAE"
					type="text"
					mask="dddd-d/dd"
					value={watch('cnae_code')}
					error={!!errors?.cnae_code}
					helperText={errors.cnae_code?.message}
					{...register('cnae_code', {
						required: {
							value: true,
							message: 'Preenchimento obrigatório.',
						},
						minLength: {
							value: 9,
							message: 'O CNAE deve conter 7 números.',
						},
					})}
				/>
			</Card>
			<Card
				title="Informe o endereço da empresa (O que está registrado no cartão CNPJ):"
				subtitle="Informe o endereço completo da empresa: Rua, número, complemento (se houver), bairro, cidade e estado."
				required
			>
				<Box
					css={{
						width: '50%',
					}}
				>
					<TextField
						label="CEP"
						type="text"
						mask={'ddddd-ddd'}
						value={zipCode}
						error={!!errors?.business_address?.zip_code}
						helperText={errors.business_address?.zip_code?.message}
						endAdornment={isLoadingCepPromise && <Icon name="Refresh" fontSize="$large" />}
						{...register('business_address.zip_code', {
							required: {
								value: true,
								message: 'Preenchimento obrigatório.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'column',
						width: '50%',
						marginTop: '$spacing24',
					}}
				>
					<TextField
						label="Endereço"
						maxLength={250}
						type="text"
						value={watch('business_address.address_line')}
						{...register('business_address.address_line', {
							required: {
								value: true,
								message: 'Preenchimento obrigatório.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Número"
							mask="ddddddddd"
							type="text"
							value={watch('business_address.building_number')}
							{...register('business_address.building_number', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled={!withNumber}
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Complemento"
							maxLength={150}
							type="text"
							value={watch('business_address.complement')}
							{...register('business_address.complement')}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ arginTop: '$spacing32', width: '50%' }}>
						<TextField
							label="Bairro"
							maxLength={200}
							type="text"
							value={watch('business_address.neighborhood')}
							{...register('business_address.neighborhood', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Cidade"
							type="text"
							value={watch('business_address.city')}
							{...register('business_address.city', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Estado"
							value={watch('business_address.state')}
							type="text"
							{...register('business_address.state', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Checkbox
						name="without_number"
						label="Endereço sem número?"
						value="false"
						onChange={handleNoNumber}
						checked={!withNumber}
					/>
				</Box>
			</Card>
			<Card
				title="Contrato Social ou Estatuto Social, registrado em junta comercial ou cartório"
				subtitle={`Formato aceito: PDF. Tamanho máximo de arquivo de upload: ${MAX_FILE_SIZE} MB.`}
				required
			>
				<Box css={{ opacity: 0, height: 0 }}>
					<input
						type="file"
						name="articles_of_incorporation"
						id="articles_of_incorporation"
						ref={inputArticlesOfIncorporationRef}
						accept=".pdf"
						onChange={handleSelectFile}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing8', display: 'flex', alignItems: 'center' }}>
					<Button variant="secondary" onClick={() => inputArticlesOfIncorporationRef.current?.click()}>
						{articleOfIncorporation?.name || 'Selecionar arquivo'}
					</Button>
				</Box>
			</Card>
			<Card
				title="Última Alteração Contratual ou Última Ata de Eleição do Responsável Legal"
				subtitle={`Formato aceito: PDF. Tamanho máximo de arquivo de upload: ${MAX_FILE_SIZE} MB.`}
				required
			>
				<Box css={{ opacity: 0, height: 0, display: hasLastContract ? 'none' : undefined }}>
					<input
						type="file"
						name="last_contract_change"
						id="last_contract_change"
						ref={inputLastContractChangeRef}
						accept=".pdf"
						onChange={handleSelectFile}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing8', display: 'flex', alignItems: 'center' }}>
					<Button
						disabled={hasLastContract}
						variant="secondary"
						onClick={() => inputLastContractChangeRef.current?.click()}
					>
						{lastContractChange?.name || 'Selecionar arquivo'}
					</Button>
				</Box>
				<Box css={{ marginTop: '$spacing24' }}>
					<Checkbox
						name="hasLastContract"
						label="Não possuo a última alteração contratual nem a última ata de eleição do responsável legal."
						value="hasLastContract"
						onChange={() => setHasLastContract((prev) => !prev)}
						checked={hasLastContract}
					/>
				</Box>
			</Card>
			<Box css={{ marginTop: '$spacing24', alignSelf: 'center' }}>
				<Button onClick={handleSubmit} disabled={isFormValid} loading={loading}>
					Avançar
				</Button>
			</Box>
			<Toast
				ref={refToast}
				direction="top-right"
				icon={<Icon name="Close" color="$currentColor" />}
				title={errorMessage}
				type="critical"
			/>
		</>
	);
};

export default CompanyData;
