/* eslint-disable no-plusplus */
import { differenceInYears, isDate, isFuture, isValid, parse } from 'date-fns';
import { Validators } from './types';

export const validator: Validators = {
	isValidCPF(value: string) {
		if (!value) return 'CPF é obrigatório.';

		const cpfFormatted = value.replace(/[^\d]+/g, '');

		if (cpfFormatted.length !== 11 || !!cpfFormatted.match(/(\d)\1{10}/)) {
			return 'CPF inválido.';
		}

		const cpfSplitted = cpfFormatted.split('').map((el) => +el);

		const rest = (count: number) =>
			((cpfSplitted.slice(0, count - 12).reduce((soma, el, index) => soma + el * (count - index), 0) * 10) % 11) % 10;

		const isValid = rest(10) === cpfSplitted[9] && rest(11) === cpfSplitted[10];
		if (!isValid) return 'CPF inválido.';

		return true;
	},
	isValidCNPJ(value: string) {
		if (!value) return 'CNPJ é obrigatório.';

		const cnpj = value.replace(/[^\d]+/g, '');

		if (cnpj.length != 14) return 'CNPJ inválido.';

		if (
			cnpj == '00000000000000' ||
			cnpj == '11111111111111' ||
			cnpj == '22222222222222' ||
			cnpj == '33333333333333' ||
			cnpj == '44444444444444' ||
			cnpj == '55555555555555' ||
			cnpj == '66666666666666' ||
			cnpj == '77777777777777' ||
			cnpj == '88888888888888' ||
			cnpj == '99999999999999'
		)
			return 'CNPJ inválido.';

		let tamanho: number = cnpj.length - 2;
		let numeros: string = cnpj.substring(0, tamanho);

		const digitos: string = cnpj.substring(tamanho);

		let soma = 0;
		let pos: number = tamanho - 7;

		for (let i: number = tamanho; i >= 1; i--) {
			soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
			if (pos < 2) pos = 9;
		}

		let resultado: number = soma % 11 < 2 ? 0 : 11 - (soma % 11);

		if (resultado != parseInt(digitos.charAt(0))) return 'CNPJ inválido.';

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (let i: number = tamanho; i >= 1; i--) {
			soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
			if (pos < 2) pos = 9;
		}

		resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
		if (resultado != parseInt(digitos.charAt(1))) return 'CNPJ inválido.';

		return true;
	},
	isValidDate(value, type) {
		const date = parse(value, 'dd/MM/yyyy', new Date());

		if (!isDate(date)) return 'Data inválida.';
		if (!isValid(date)) return 'Data inválida.';

		const currentDate = new Date();

		if ((type === 'openingDate' || type === 'birthDate') && isFuture(date)) return 'Data inválida.';

		if (type === 'birthDate') {
			const age = differenceInYears(currentDate, date);

			if (age < 18) return 'Você deve ter mais de 18 anos.';
			if (age > 100) return 'Data inválida.';
		}

		if (date.getFullYear() < 1900) return 'Data inválida.';

		return true;
	},
	isValidPhone(phone: string) {
		const reg = /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/;
		return reg.test(phone);
	},
};
