import { useFieldArray, useFormContext } from 'react-hook-form';
import { BusinessOwner, CompanyForm, PersonRepresentative } from '../CompanyData/types';
import { Box, Button, ButtonGroup, Separator, Typography } from '@maistodos/design-system-web';
import { useNavigate, useParams } from 'react-router-dom';
import OwnerPersonal from './components/OwnerPersonal';
import OwnerBusiness from './components/OwnerBusiness';
import { useMemo, useState } from 'react';
import { useEffectMixPanelSendEvent } from '../../../../../libs/mixpanel';

const OwnerPartner = () => {
	const { companySize } = useParams();
	const navigate = useNavigate();
	const [isPersonalValid, setIsPersonalValid] = useState<boolean>(false);
	const [isBusinessValid, setIsBusinessValid] = useState<boolean>(false);
	const { formState, control, watch } = useFormContext<CompanyForm>();
	const { isValid } = formState;
	const {
		fields: ownerPersonalFields,
		append: appendOwnerPersonal,
		remove: removePersonOwner,
	} = useFieldArray<CompanyForm>({
		control,
		name: 'owners_personal',
	});
	const {
		fields: ownerBusinessFields,
		append: appendOwnerBusiness,
		remove: removeBusinessOwner,
	} = useFieldArray<CompanyForm>({
		control,
		name: 'owners_business',
	});
	const handleAddOwner = () => {
		appendOwnerPersonal({} as never);
	};
	const handleAddOwnerBusiness = () => {
		appendOwnerBusiness({} as never);
	};
	const handlePreviousStep = () => {
		navigate(`/form/${companySize}/1`);
	};

	const handleNextStep = () => {
		navigate(`/form/${companySize}/3`);
	};

	const handleRemoveOwner = (index: number, type: 'business' | 'person'): void => {
		if (type === 'person') {
			removePersonOwner(index);
		}
		if (type === 'business') {
			removeBusinessOwner(index);
		}
	};

	const calculateTotalPercentage = (ownersArray: (PersonRepresentative | BusinessOwner)[]) => {
		if (!ownersArray) return 0;
		return ownersArray.reduce((total: number, owner) => total + Number(owner?.participation_percentage ?? 0), 0);
	};

	const sumPercentagesEqualToOneHundred = () => {
		const totalBusiness = calculateTotalPercentage(watch().owners_business);
		const totalPersonal = calculateTotalPercentage(watch().owners_personal);
		const totalPercentage = totalBusiness + totalPersonal;
		return totalPercentage === 100;
	};

	const disabled = useMemo(
		() =>
			!isValid ||
			!sumPercentagesEqualToOneHundred() ||
			(!isPersonalValid && ownerPersonalFields?.length > 0) ||
			(!isBusinessValid && ownerBusinessFields?.length > 0),
		[
			isValid,
			sumPercentagesEqualToOneHundred,
			isPersonalValid,
			isBusinessValid,
			ownerPersonalFields,
			ownerBusinessFields,
		],
	);

	useEffectMixPanelSendEvent({
		event: 'new_account_pjotao_user',
	});

	return (
		<>
			{ownerPersonalFields.map((_, id) => {
				return <OwnerPersonal index={id} key={id} removeOwner={handleRemoveOwner} setIsValid={setIsPersonalValid} />;
			})}
			{ownerBusinessFields.map((_, id) => {
				return <OwnerBusiness index={id} key={id} removeOwner={handleRemoveOwner} setIsValid={setIsBusinessValid} />;
			})}
			<Box
				css={{
					display: 'flex',
					flexDirection: 'column',
					gap: '$spacing12',
					marginBottom: '$spacing16',
				}}
			>
				<ButtonGroup direction="row">
					<Button variant="secondary" onClick={handleAddOwnerBusiness}>
						Adicionar novo sócio PJ
					</Button>
					<Button variant="secondary" onClick={handleAddOwner}>
						Adicionar novo sócio PF
					</Button>
				</ButtonGroup>
				<Separator css={{ marginTop: '$spacing16', marginBottom: '$spacing16' }} />
				{(watch().owners_business?.length > 0 || watch().owners_personal?.length > 0) &&
					!sumPercentagesEqualToOneHundred() && (
						<Box css={{ display: 'flex', justifyContent: 'center', marginBottom: '$spacing16' }}>
							<Typography variant="large" css={{ color: '$critical500' }}>
								Erro: O somatório das porcentagens dos sócios deve ser igual a 100%.
							</Typography>
						</Box>
					)}
				<ButtonGroup direction="row">
					<Button variant="secondary" onClick={handlePreviousStep}>
						Voltar
					</Button>
					<Button onClick={handleNextStep} disabled={disabled}>
						Avançar
					</Button>
				</ButtonGroup>
			</Box>
		</>
	);
};

export default OwnerPartner;
