import { Box, styled } from '@maistodos/design-system-web';

export const BoxGroup = styled(Box, {
	borderWidth: '1px',
	borderColor: '$neutral300',
	padding: '15px',
	display: 'flex',
	flexDirection: 'column',
	borderStyle: 'solid',
	borderRadius: '15px',
	width: 800,
	margin: '0 auto',
	gap: '$spacing24',
	position: 'relative',
});

export const CloseButton = styled('button', {
	border: 'none',
	background: 'none',
	cursor: 'pointer',
});
