import { Box } from '@maistodos/design-system-web';
import { useNavigate, useParams } from 'react-router-dom';
import Corporate from './Corporate';
import Small from './Small';

const Forms = () => {
	const { companySize, step } = useParams();
	const navigate = useNavigate();
	if (!step) {
		navigate(`/form/${companySize}/1`);
	}
	const actualStep = step ? parseInt(step) : 1;
	const formInputChanged = true;
	window.addEventListener('beforeunload', function (e) {
		if (formInputChanged) {
			const confirmationMessage = 'You have unsaved changes';

			(e || window.event).returnValue = confirmationMessage; //Gecko + IE
			return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
		}
	});

	return (
		<Box
			css={{
				display: 'flex',
				alignItems: 'stretch',
				width: '100vw',
				height: '100vh',
				position: 'relative',
			}}
		>
			<Box
				css={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflowX: 'auto',
					padding: '$spacing32',
					'@media (max-height: 800px)': {
						alignItems: 'initial',
					},
				}}
			>
				<Box
					css={{
						display: 'flex',
						width: '100%',
						height: '100%',
						flexDirection: 'column',
					}}
				>
					<Box
						css={{
							marginTop: '$spacing24',
							marginBottom: '$spacing48',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<img src={'/assets/logo-maistodos.svg'} width={240} height={60} />
					</Box>
					<Box
						css={{
							width: 800,
							margin: '0 auto',
							display: 'flex',
							flexDirection: 'column',
							gap: companySize === 'corporate' ? '$spacing16' : '$spacing24',
						}}
					>
						{companySize === 'corporate' && <Corporate step={actualStep} />}
						{companySize === 'small' && <Small />}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Forms;
