import { useCallback } from 'react';

import { Box, Button, Typography } from '@maistodos/design-system-web';

import { useNavigate } from 'react-router-dom';

const NotFoundScreen = () => {
	const navigate = useNavigate();

	const onRedirect = useCallback(() => {
		navigate('/');
	}, [navigate]);

	return (
		<Box
			css={{
				display: `flex`,
				flex: 1,
				flexDirection: `column`,
				alignItems: `center`,
				justifyContent: `center`,
				gap: `$spacing32`,
				paddingTop: `$spacing80`,
			}}
		>
			<Box
				css={{
					width: `442px`,
					height: `309px`,
					backgroundImage: `url('/images/error/illustration.png')`,
					backgroundPosition: `center`,
					backgroundRepeat: `no-repeat`,
					backgroundSize: `cover`,
				}}
			/>
			<Box
				css={{
					display: `flex`,
					flexDirection: `column`,
					alignItems: `center`,
					justifyContent: `center`,
					gap: `$spacing24`,
				}}
			>
				<Typography align="center" variant="h1" weight="bold" css={{ color: '$neutral500' }}>
					Ops... Não encontramos essa página!
				</Typography>
				<Typography align="center" variant="large" weight="regular" css={{ color: '$neutral500', maxWidth: `606px` }}>
					A página que você tentou acessar não existe mais ou está em manutenção. Mas pode ficar tranquilo que estamos
					cuidando do seu dinheiro.
				</Typography>
			</Box>
			<Button size="large" variant="primary" onClick={onRedirect}>
				Voltar para tela inicial
			</Button>
		</Box>
	);
};

export default NotFoundScreen;
