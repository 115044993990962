import { createContext, useContext, useRef } from 'react';

import { Toast } from '@maistodos/design-system-web';
import type { ToastRef } from '@maistodos/design-system-web/types/components/Feedback/Toast/types';

import Icon from '../../components/Icon';
import { PrivateToastContextType, PrivateToastProviderType } from './types';

const PrivateToastContext = createContext({} as PrivateToastContextType);

export const PrivateToastProvider = ({ children }: PrivateToastProviderType) => {
  const refToast = useRef<ToastRef | null>(null);

  return (
    <PrivateToastContext.Provider value={{ refToast }}>
      {children}
      <Toast
        ref={refToast}
        direction="top-right"
        icon={<Icon name="Close" color="$currentColor" />}
        title="Por favor preencha os dados corretamente."
        type="warning"
      />
    </PrivateToastContext.Provider>
  );
}

export const useToast = () => useContext(PrivateToastContext);
