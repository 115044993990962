import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Outlet, useParams } from 'react-router-dom';

import { useToast } from '../../contexts';
import { NavigateScreen } from '../NavigateScreen';

export const PrivateRouter = () => {
  const { refToast } = useToast();
  const { companySize, step } = useParams();
  const { watch } = useFormContext();

  const hasBusinessType = !!sessionStorage.getItem('INTERNAL_BUSINESS_TYPE');
  const hasFormContextValues = Object.keys(watch()).length > 0;
  const isInitialFormStep = !(step === '2' || step == '3');

  useEffect(() => {
    if (!hasBusinessType) {
      refToast?.current?.publish();
    } else if (!isInitialFormStep && !hasFormContextValues) {
      refToast?.current?.publish();
    }
  }, [hasBusinessType, hasFormContextValues]);

  if (!hasBusinessType) {
    return <NavigateScreen refToast={refToast} routeToNavigate="/" />;
  }

  if (!isInitialFormStep && !hasFormContextValues) {
    return (
      <NavigateScreen
        refToast={refToast}
        routeToNavigate={`/form/${companySize}/1`}
      />
    );
  }

  return <Outlet />;
};
