import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import { globalStyle as GlobalStyle } from './config/base/styles/global';
import Routes from './routes';
import { PrivateToastProvider } from './contexts';

const app: HTMLElement = document.getElementById('app') as HTMLElement;

const root = createRoot(app);

root.render(
	<StrictMode>
		<div className="App">
			<PrivateToastProvider>
				<Routes />
				<GlobalStyle />
			</PrivateToastProvider>
		</div>
	</StrictMode>,
);
