import { SmallProps, StepsEnum } from './types';
import CompanyData from './screens/CompanyData';
import OwnerPartner from './screens/OwnerPartner';
import LegalRepresentant from './screens/LegalRepresentant';
import Steps from '../../../components/Steps';
import { useParams } from 'react-router-dom';

const Corporate = (props: SmallProps) => {
	const { step } = useParams();
	const actualStep = step ? parseInt(step) : 1;
	return (
		<>
			<Steps steps={[StepsEnum.Step1, StepsEnum.Step2, StepsEnum.Step3]} currentStep={actualStep - 1} />
			{props.step === 1 && <CompanyData />}
			{props.step === 2 && <OwnerPartner />}
			{props.step === 3 && <LegalRepresentant />}
		</>
	);
};

export default Corporate;
