import { useCallback, useEffect, useState } from 'react';
import { BoxGroup, CloseButton } from './style';
import Card from '../../../../../../../components/Card';
import {
	Box,
	Checkbox,
	SelectField,
	SelectItem,
	Separator,
	TextField,
	Typography,
	unmaskString,
} from '@maistodos/design-system-web';
import { useFormContext } from 'react-hook-form';
import cep from 'cep-promise';
import { OwnerPersonProps } from './types';
import { CompanyForm } from '../../../CompanyData/types';
import { ocupationsByBankly } from '../../../../../../../services/ocupations';
import { validator } from '../../../../../../../utils/validator';
import Icon from '../../../../../../../components/Icon';
import { declaredIncomeOptions } from '../../../../../constants';

const OwnerPersonal = ({ index, removeOwner, setIsValid }: OwnerPersonProps) => {
	const { register, watch, formState, setValue, setError } = useFormContext<CompanyForm>();
	const { errors } = formState;

	const [withNumber, setWithNumber] = useState<boolean>(true);
	const [isLoadingCepPromise, setisLoadingCepPromise] = useState<boolean>(false);
	const [isCepPromiseSuccess, setIsCepPromiseSuccess] = useState<boolean>(false);
	const zipCode = watch(`owners_personal.${index}.address.zip_code`);
	const pepLevel = watch(`owners_personal.${index}.pep.level`);
	const viewAddress = isCepPromiseSuccess && zipCode?.length === 9;

	const handleZipCode = useCallback(async (): Promise<void> => {
		setIsCepPromiseSuccess(false);
		setisLoadingCepPromise(true);
		try {
			const address = await cep(zipCode);
			setValue(`owners_personal.${index}.address.address_line`, address.street);
			setValue(`owners_personal.${index}.address.city`, address.city);
			setValue(`owners_personal.${index}.address.neighborhood`, address.neighborhood);
			setValue(`owners_personal.${index}.address.state`, address.state);
			setValue(`owners_personal.${index}.address.country`, 'BR');
			setIsCepPromiseSuccess(true);
		} catch {
			setIsCepPromiseSuccess(false);
			setError(`owners_personal.${index}.address.zip_code`, { type: 'required', message: 'CEP inválido.' });
			setValue(`owners_personal.${index}.address.address_line`, '');
			setValue(`owners_personal.${index}.address.state`, '');
			setValue(`owners_personal.${index}.address.city`, '');
			setValue(`owners_personal.${index}.address.neighborhood`, '');
			setValue(`owners_personal.${index}.address.building_number`, '');
			setValue(`owners_personal.${index}.address.complement`, '');
			setWithNumber(true);
		} finally {
			setisLoadingCepPromise(false);
		}
	}, [cep, zipCode]);

	const handleNoNumber = useCallback(() => {
		setWithNumber((prev) => {
			const value = !prev;

			if (value) setValue(`owners_personal.${index}.address.building_number`, '', { shouldValidate: true });
			if (!value) setValue(`owners_personal.${index}.address.building_number`, 'S/N', { shouldValidate: true });

			return !prev;
		});
	}, [index]);

	const validateFormFields = useCallback(() => setIsValid(viewAddress && !!pepLevel), [viewAddress, pepLevel]);

	useEffect(() => {
		if (zipCode?.length === 9) {
			void handleZipCode();
		}
	}, [zipCode]);

	useEffect(() => {
		validateFormFields();
	}, [viewAddress, pepLevel]);

	return (
		<>
			<BoxGroup>
				<CloseButton
					css={{ position: 'absolute', right: '15px' }}
					title="Excluir sócio"
					onClick={() => removeOwner(index, 'person')}
				>
					<Icon name="Close" color="$neutral500" />
				</CloseButton>
				<Typography variant={'h5'} weight={'semibold'} color={'$neutral600'} css={{ marginBottom: '$spacing12' }}>
					Sócio {index + 1 > 1 && index + 1} - Pessoa física
				</Typography>
				<Card title="Nome de registro, como no documento (RG ou CNH):" subtitle="Conforme documento legal" required>
					<TextField
						label="Nome completo"
						type="text"
						maxLength={250}
						value={watch(`owners_personal.${index}.register_name`)}
						error={!!errors?.owners_personal?.[index]?.register_name}
						helperText={errors.owners_personal?.[index]?.register_name?.message}
						{...register(`owners_personal.${index}.register_name`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 3,
								message: 'O campo não foi preenchido corretamente.',
							},
						})}
					/>
				</Card>
				<Card title="Nome de preferência (como podemos nos referir a você):" required>
					<TextField
						label="Nome de preferência"
						type="text"
						maxLength={250}
						value={watch(`owners_personal.${index}.social_name`)}
						error={!!errors?.owners_personal?.[index]?.social_name}
						helperText={errors.owners_personal?.[index]?.social_name?.message}
						{...register(`owners_personal.${index}.social_name`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
						})}
					/>
				</Card>
				<Card title="CPF (digite apenas os números):" required>
					<TextField
						label="CPF"
						type="text"
						mask={'ddd.ddd.ddd-dd'}
						value={watch(`owners_personal.${index}.document_number`)}
						error={!!errors?.owners_personal?.[index]?.document_number}
						helperText={errors.owners_personal?.[index]?.document_number?.message}
						{...register(`owners_personal.${index}.document_number`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 14,
								message: 'O campo não foi preenchido corretamente.',
							},
							validate: (e) => validator.isValidCPF(e),
						})}
					/>
				</Card>
				<Card title="Nome da mãe (insira o nome completo como no documento):" required>
					<TextField
						label="Nome da mãe"
						type="text"
						maxLength={250}
						value={watch(`owners_personal.${index}.mother_name`)}
						error={!!errors?.owners_personal?.[index]?.mother_name}
						helperText={errors.owners_personal?.[index]?.mother_name?.message}
						{...register(`owners_personal.${index}.mother_name`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 6,
								message: 'O campo não foi preenchido corretamente.',
							},
						})}
					/>
				</Card>
				<Card title="E-mail" required>
					<TextField
						label="E-mail"
						type="text"
						maxLength={255}
						value={watch(`owners_personal.${index}.email`)}
						error={!!errors?.owners_personal?.[index]?.email}
						helperText={errors.owners_personal?.[index]?.email?.message}
						{...register(`owners_personal.${index}.email`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'E-mail inválido.',
							},
						})}
					/>
				</Card>
				<Card title="Principal número celular para contato com DDD:" required>
					<TextField
						label="Telefone"
						type="text"
						mask="(dd) ddddd-dddd"
						value={watch(`owners_personal.${index}.phone.number`)}
						error={!!errors?.owners_personal?.[index]?.phone?.number}
						helperText={errors.owners_personal?.[index]?.phone?.number?.message}
						{...register(`owners_personal.${index}.phone.number`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 15,
								message: 'O preenchimento é obrigatório.',
							},
							validate: (e) => validator.isValidPhone(unmaskString(e)) || 'Número de celular inválido.',
						})}
					/>
				</Card>
				<Card
					title="Data de nascimento. Siga o modelo de preenchimento DD/MM/AAAA:"
					subtitle="É necessário ser maior de 18 anos."
					required
				>
					<TextField
						label="Data de nascimento"
						type="text"
						mask="dd/dd/dddd"
						value={watch(`owners_personal.${index}.birth_date`)}
						error={!!errors?.owners_personal?.[index]?.birth_date}
						helperText={errors.owners_personal?.[index]?.birth_date?.message}
						{...register(`owners_personal.${index}.birth_date`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 10,
								message: 'O campo não foi preenchido corretamente.',
							},
							validate: (e) => validator.isValidDate(e, 'birthDate'),
						})}
					/>
				</Card>
				<Card
					title="Percentual de participação"
					subtitle="Só é aceito números inteiros, favor se atentar que no final a soma das porcentagens devem ser 100%."
					required
				>
					<TextField
						label="Percentual de participação"
						type="text"
						mask={'ddd'}
						value={watch(`owners_personal.${index}.participation_percentage`)}
						error={!!errors?.owners_personal?.[index]?.participation_percentage}
						helperText={errors.owners_personal?.[index]?.participation_percentage?.message}
						endAdornment="%"
						{...register(`owners_personal.${index}.participation_percentage`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
							minLength: {
								value: 1,
								message: 'O preenchimento é obrigatório.',
							},
							validate: (value) => (value && value > 100 ? 'Porcentagem inválida.' : true),
						})}
					/>
				</Card>
				<Card title="Faixa de renda declarada" required>
					<SelectField
						value={watch(`owners_personal.${index}.declared_income`)}
						placeholder="Selecione a faixa de renda declarada"
						error={!!errors?.owners_personal?.[index]?.declared_income}
						helperText={errors.owners_personal?.[index]?.declared_income?.message}
						{...register(`owners_personal.${index}.declared_income`, {
							required: {
								value: true,
								message: 'O preenchimento é obrigatório.',
							},
						})}
						onChange={(e) =>
							setValue(`owners_personal.${index}.declared_income`, e.target.value, { shouldValidate: true })
						}
					>
						{declaredIncomeOptions.map((option) => (
							<SelectItem value={option.value} key={option.value}>
								{option.label}
							</SelectItem>
						))}
					</SelectField>
				</Card>
				<Card title="Pessoa politicamente exposta" required>
					<Box css={{ marginBottom: '$spacing24' }}>
						<Checkbox
							name={`owners_personal.${index}.pep.level`}
							label="Não sou uma pessoa politicamente exposta"
							value="none"
							onChange={() => setValue(`owners_personal.${index}.pep.level`, 'none')}
							checked={pepLevel === 'none'}
						/>
					</Box>
					<Box css={{ marginBottom: '$spacing24' }}>
						<Checkbox
							name={`owners_personal.${index}.pep.level`}
							label="Sou uma pessoa politicamente exposta"
							value="self"
							onChange={() => setValue(`owners_personal.${index}.pep.level`, 'self')}
							checked={pepLevel === 'self'}
						/>
					</Box>
					<Box>
						<Checkbox
							name={`owners_personal.${index}.pep.level`}
							label="Possuo relação com pessoa politicamente exposta"
							value="related"
							onChange={() => setValue(`owners_personal.${index}.pep.level`, 'related')}
							checked={pepLevel === 'related'}
						/>
					</Box>
				</Card>
				<Card title="Ocupação principal" required>
					<SelectField
						placeholder="Selecione a ocupação principal"
						error={!!errors?.owners_personal?.[index]?.occupation}
						helperText={errors.owners_personal?.[index]?.occupation?.message}
						value={watch(`owners_personal.${index}.occupation`)}
						{...register(`owners_personal.${index}.occupation`, {
							required: {
								value: true,
								message: 'É necessário informar a sua ocupação principal.',
							},
						})}
						onChange={(e) => setValue(`owners_personal.${index}.occupation`, e.target.value, { shouldValidate: true })}
					>
						{ocupationsByBankly().map((item) => (
							<SelectItem value={item.id} key={item.id}>
								{item.description}
							</SelectItem>
						))}
					</SelectField>
				</Card>
				<Card
					title={`Endereço completo do sócio:`}
					subtitle={`Informe o endereço completo do sócio: Rua, número, complemento (se houver), bairro, cidade e estado.`}
					required
				>
					<Box
						css={{
							width: '50%',
						}}
					>
						<TextField
							label="CEP"
							type="text"
							mask={'ddddd-ddd'}
							value={watch(`owners_personal.${index}.address.zip_code`)}
							error={!!errors?.owners_personal?.[index]?.address?.zip_code}
							helperText={errors.owners_personal?.[index]?.address?.zip_code?.message}
							endAdornment={isLoadingCepPromise && <Icon name="Refresh" fontSize="$large" />}
							{...register(`owners_personal.${index}.address.zip_code`, {
								required: {
									value: true,
									message: 'É necessário preencher com CEP.',
								},
							})}
						/>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'column',
							width: '50%',
							marginTop: '$spacing24',
						}}
					>
						<TextField
							label="Endereço"
							maxLength={250}
							type="text"
							value={watch(`owners_personal.${index}.address.address_line`)}
							{...register(`owners_personal.${index}.address.address_line`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
						/>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
							<TextField
								label="Número"
								mask="ddddddddd"
								type="text"
								value={watch(`owners_personal.${index}.address.building_number`)}
								{...register(`owners_personal.${index}.address.building_number`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
								disabled={!withNumber}
							/>
						</Box>
						<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
							<TextField
								label="Complemento"
								maxLength={150}
								type="text"
								value={watch(`owners_personal.${index}.address.complement`)}
								{...register(`owners_personal.${index}.address.complement`)}
							/>
						</Box>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Box css={{ arginTop: '$spacing32', width: '50%' }}>
							<TextField
								label="Bairro"
								maxLength={200}
								type="text"
								value={watch(`owners_personal.${index}.address.neighborhood`)}
								{...register(`owners_personal.${index}.address.neighborhood`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
							/>
						</Box>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
							<TextField
								label="Cidade"
								type="text"
								value={watch(`owners_personal.${index}.address.city`)}
								{...register(`owners_personal.${index}.address.city`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
								disabled
							/>
						</Box>
						<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
							<TextField
								label="Estado"
								value={watch(`owners_personal.${index}.address.state`)}
								type="text"
								{...register(`owners_personal.${index}.address.state`, {
									required: {
										value: true,
										message: 'Preenchimento obrigatório.',
									},
								})}
								disabled
							/>
						</Box>
					</Box>
					<Box
						css={{
							display: viewAddress ? 'flex' : 'none',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						<Checkbox
							name={`owners_personal.${index}.without_number`}
							label="Endereço sem número?"
							value="false"
							onChange={handleNoNumber}
							checked={!withNumber}
						/>
					</Box>
				</Card>
			</BoxGroup>
			<Separator />
		</>
	);
};

export default OwnerPersonal;
