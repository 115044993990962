import axios from 'axios';

const generateBasicToken = () => {
	const token =
		process.env.NODE_ENV === 'DEVELOPMENT'
			? 'customer.success.form:1234'
			: 'customer.success.form:XE|g?45]wmODrq*H"|9)';
	const basicAuth = btoa(token).toString();
	return `Basic ${basicAuth}`;
};

const backofficeService = axios.create({
	baseURL: 'https://backoffice.maistodos.com.br/api/',
	headers: {
		'Content-Type': 'application/json',
		Authorization: generateBasicToken(),
	},
});

delete backofficeService.defaults.headers.common['User-Agent'];
delete backofficeService.defaults.headers.common['Accept'];
delete backofficeService.defaults.headers.common['Content-Length'];

export { backofficeService };
