import { useCallback, useState } from 'react';

import { Box, Button, Radio, Typography } from '@maistodos/design-system-web';

import { DATA } from './utils';
import type { InternalBusinessType } from './types';
import WarningAdvertisement from '../../../components/WarningAdvertisement';
import { useNavigate } from 'react-router-dom';
import { useEffectMixPanelSendEvent } from '../../../libs/mixpanel';

const Initial = () => {
	const navigate = useNavigate();
	const [response, setResponse] = useState<InternalBusinessType>();

	const onSubmit = useCallback(() => {
		sessionStorage.setItem('INTERNAL_BUSINESS_TYPE', response as InternalBusinessType);
		navigate('/home');
	}, [response]);

	useEffectMixPanelSendEvent({
		event: 'new_account_start',
	});

	return (
		<Box
			css={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				css={{
					marginTop: '$spacing40',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<img src={'/assets/logo-maistodos.svg'} width={240} height={60} />
			</Box>
			<Box
				css={{
					marginTop: '$spacing40',
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography variant="h5" css={{ fontWeight: '$bold', color: '$neutral600' }}>
					Abra sua conta
				</Typography>
				<Box css={{ marginTop: '$spacing40' }}>
					<img src={'/assets/todos.svg'} />
				</Box>
				<Box css={{ marginTop: '$spacing40' }}>
					<Typography variant="h5" css={{ fontWeight: '$bold', color: '$neutral600' }}>
						Sua empresa faz parte do nosso grupo?
					</Typography>
				</Box>
				<Box css={{ marginTop: '$spacing16' }}>
					<Typography variant="large">No momento, estamos operando somente com empresas internas.</Typography>
				</Box>
				<Box
					css={{
						display: 'flex',
						marginTop: '$spacing24',
						justifyContent: 'center',
					}}
				>
					<WarningAdvertisement text="Caso não faça parte do grupo TODOS Empreendimentos, selecione a opção “Não faço parte do grupo” e entre na lista de espera." />
				</Box>
				<Box
					css={{
						marginTop: '$spacing40',
						gap: '$spacing16',
						flexDirection: 'row',
						flexWrap: 'wrap',
						display: 'flex',
						maxWidth: 240 * 3 + 32,
					}}
				>
					{DATA.map((item) => (
						<Box
							key={item.value}
							css={{
								paddingBottom: '$spacing24',
								borderBottom: '1px solid',
								borderColor: '$neutral200',
								minWidth: 240,
								flex: '0 0 240px',
								marginBottom: '$spacing32',
							}}
						>
							<Radio
								label={item.label}
								name={item.value}
								onChange={() => setResponse(item.value as InternalBusinessType)}
								value={item.value}
								checked={response === item.value}
							/>
						</Box>
					))}
				</Box>
				<Box css={{ marginBottom: '$spacing16' }}>
					<Button onClick={onSubmit} disabled={!response}>
						Avançar
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Initial;
