import { Box, Typography } from '@maistodos/design-system-web';

import type { WarningAdvertisementProps } from './types';
import Icon from '../Icon';

const WarningAdvertisement = ({ text }: WarningAdvertisementProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        width: '80%',
        gap: '$spacing16',
        alignItems: 'center',
        borderRadius: '$medium',
        boxShadow: '0px 4px 16px 0px rgba(104, 104, 104, 0.08)',
      }}
    >
      <Box
        css={{
          height: 76,
          width: '1%',
          backgroundColor: '$warning600',
          borderRadius: '8px 0px 0px 8px',
        }}
      />
      <Icon name='Info' color="$warning600" fontSize='$h3' />
      <Typography variant="medium" css={{ color: '$neutral400' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default WarningAdvertisement;
