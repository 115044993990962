import { type ChangeEvent, useCallback, useState, useRef, useEffect, useMemo } from 'react';
import Card from '../../../components/Card';
import {
	Box,
	Button,
	Checkbox,
	SelectField,
	SelectItem,
	TextField,
	Toast,
	Typography,
	unmaskString,
} from '@maistodos/design-system-web';
import { useFormContext } from 'react-hook-form';
import cep from 'cep-promise';
import { CompanySmallForm } from './types';
import { validator } from '../../../utils/validator';

import { useNavigate } from 'react-router-dom';
import { createSmallBusiness } from '../../../services/createSmallBusiness';
import { ToastRef } from '@maistodos/design-system-web/types/components/Feedback/Toast/types';
import Icon from '../../../components/Icon';
import { InternalBusinessType } from '../../Initial/screens/types';
import { declaredAnnualBillingType } from '../../../utils/types';
import { annualBillingOptions } from '../constants';
import { useEffectMixPanelSendEvent } from '../../../libs/mixpanel';

const Small = () => {
	const { register, watch, setValue, formState, setError, reset } = useFormContext<CompanySmallForm>();
	const { errors, isValid } = formState;
	const navigate = useNavigate();
	const refToastError = useRef<ToastRef | null>(null);

	const [withNumber, setWithNumber] = useState<boolean>(true);
	const [loadingRetool, setLoadingRetool] = useState<boolean>(false);
	const [isLoadingCepPromise, setisLoadingCepPromise] = useState<boolean>(false);
	const [isCepPromiseSuccess, setIsCepPromiseSuccess] = useState<boolean>(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string): void => {
		const { value } = e.target;
		switch (name) {
			case 'business_type':
				setValue(name, value);
				break;
			case 'business_size':
				setValue(name, value);
				break;
			default:
				break;
		}
	};

	const businessType = watch('business_type') as unknown;
	const businessSize = watch('business_size') as unknown;
	const zipCode = watch('business_address.zip_code') as string;
	const viewAddress = isCepPromiseSuccess && zipCode?.length === 9;

	const handleZipCode = useCallback(async (): Promise<void> => {
		setIsCepPromiseSuccess(false);
		setisLoadingCepPromise(true);
		try {
			const address = await cep(zipCode);
			if (address.street) {
				setValue('business_address.address_line', address.street);
			}
			setValue('business_address.state', address.state);
			setValue('business_address.city', address.city);
			if (address.neighborhood) {
				setValue('business_address.neighborhood', address.neighborhood);
			}
			setValue('business_address.country', 'BR');
			setIsCepPromiseSuccess(true);
		} catch {
			setIsCepPromiseSuccess(false);
			setError('business_address.zip_code', { type: 'required', message: 'CEP inválido.' });
			setValue('business_address.address_line', '');
			setValue('business_address.state', '');
			setValue('business_address.city', '');
			setValue('business_address.neighborhood', '');
			setValue('business_address.building_number', '');
			setValue('business_address.complement', '');
			setWithNumber(true);
		} finally {
			setisLoadingCepPromise(false);
		}
	}, [cep, zipCode]);

	const handleSubmitRetool = async (): Promise<void> => {
		setLoadingRetool(true);

		try {
			await createSmallBusiness({
				address: { ...watch('business_address'), zip_code: unmaskString(watch('business_address.zip_code')) },
				declared_annual_billing: watch('declared_annual_billing'),
				document_number: unmaskString(watch('business_document')),
				email: watch('business_email'),
				legal_representative_document: unmaskString(watch('representative_document')),
				name: watch('business_name'),
				size: watch('business_size'),
				trading_name: watch('trading_name'),
				type: watch('business_type'),
				internal_business_type: sessionStorage.getItem('INTERNAL_BUSINESS_TYPE') as InternalBusinessType,
			});
			reset();
			navigate(`/finish`);
		} catch {
			refToastError?.current?.publish();
		} finally {
			setLoadingRetool(false);
		}
	};

	const handleNoNumber = useCallback(() => {
		setWithNumber((prev) => {
			const value = !prev;

			if (value) setValue('business_address.building_number', '', { shouldValidate: true });
			if (!value) setValue('business_address.building_number', 'S/N', { shouldValidate: true });

			return !prev;
		});
	}, []);

	const disabled = useMemo(
		() => !isValid || !viewAddress || !businessType || !businessSize,
		[isValid, viewAddress, businessType, businessSize],
	);

	useEffect(() => {
		if (zipCode?.length === 9) {
			void handleZipCode();
		}
	}, [zipCode]);

	useEffectMixPanelSendEvent({
		event: 'new_account_pjotinha_start',
	});

	return (
		<>
			<Box
				css={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '$spacing12',
					gap: '$spacing8',
					cursor: 'pointer',
				}}
				onClick={() => navigate(`/home`)}
			>
				<Icon name="ArrowLeft" color="$neutral600" />
				<Typography variant="h6" weight="bold" color="$neutral600">
					Preencha todos os campos para avançar
				</Typography>
			</Box>
			<Card title="Selecione o tipo da sua empresa:" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_type"
						label="MEI - Microempreendedor Individual"
						value="mei"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'mei'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_type"
						label="EI - Empreendedor Individual"
						value="ei"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'ei'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_type"
						label="EIRELI - Empresa Individual de Responsabilidade Limitada"
						value="eireli"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'eireli'}
					/>
				</Box>
				<Box>
					<Checkbox
						name="business_type"
						label="SLU - Sociedade limitada Unipessoal (LTDA apenas com um sócio)"
						value="slu"
						onChange={(e) => handleChange(e, 'business_type')}
						checked={businessType === 'slu'}
					/>
				</Box>
			</Card>
			<Card title="Selecione o tamanho da sua empresa:" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_size"
						label="EPP - Empresa de pequeno porte"
						value="epp"
						onChange={(e) => handleChange(e, 'business_size')}
						checked={businessSize === 'epp'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name="business_size"
						label="ME - Microempresa"
						value="me"
						onChange={(e) => handleChange(e, 'business_size')}
						checked={businessSize === 'me'}
					/>
				</Box>
				<Box>
					<Checkbox
						name="business_size"
						label="MEI - Microempreendedor Individual"
						value="mei"
						onChange={(e) => handleChange(e, 'business_size')}
						checked={businessSize === 'mei'}
					/>
				</Box>
			</Card>
			<Card title="Informe o CNPJ (digite apenas os números):" required>
				<TextField
					label="CNPJ"
					mask="dd.ddd.ddd/dddd-dd"
					type="text"
					error={!!errors?.business_document}
					helperText={errors.business_document?.message}
					value={watch('business_document')}
					{...register('business_document', {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 18,
							message: 'São necessários 14 números.',
						},
						validate: (e) => validator.isValidCNPJ(e),
					})}
				/>
			</Card>
			<Card title="Insira o número do CPF do representante legal:" required>
				<TextField
					label="CPF"
					mask="ddd.ddd.ddd-dd"
					type="text"
					error={!!errors?.representative_document}
					helperText={errors.representative_document?.message}
					value={watch('representative_document')}
					{...register('representative_document', {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 14,
							message: 'São necessários 11 números.',
						},
						validate: (e) => validator.isValidCPF(e),
					})}
				/>
			</Card>
			<Card title="Nome da empresa (razão social):" required>
				<TextField
					label="Razão social"
					type="text"
					maxLength={128}
					error={!!errors?.business_name}
					helperText={errors.business_name?.message}
					value={watch('business_name')}
					{...register('business_name', {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
				/>
			</Card>
			<Card title="Nome fantasia (nome comercial):">
				<TextField
					label="Nome fantasia"
					type="text"
					error={!!errors?.trading_name}
					helperText={errors.trading_name?.message}
					value={watch('trading_name')}
					maxLength={128}
					{...register('trading_name')}
				/>
			</Card>
			<Card title="E-mail para contato com a empresa:" required>
				<TextField
					label="E-mail"
					type="email"
					maxLength={255}
					error={!!errors?.business_email}
					helperText={errors.business_email?.message}
					value={watch('business_email')}
					{...register('business_email', {
						required: {
							value: true,
							message: 'Preenchimento obrigatório.',
						},
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'E-mail inválido.',
						},
					})}
				/>
			</Card>
			<Card
				title="Faturamento anual da empresa:"
				subtitle="Caso a empresa ainda não tenha 1 ano, inserir a estimativa."
				required
			>
				<SelectField
					placeholder="Selecione o faturamento anual da empresa"
					error={!!errors?.declared_annual_billing}
					value={watch(`declared_annual_billing`)}
					{...register(`declared_annual_billing`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
					onChange={(e) =>
						setValue('declared_annual_billing', e.target.value as declaredAnnualBillingType, { shouldValidate: true })
					}
				>
					{annualBillingOptions.map((option) => (
						<SelectItem key={option.value} value={option.value}>
							{option.label}
						</SelectItem>
					))}
				</SelectField>
			</Card>
			<Card
				title="Informe o endereço da empresa (O que está registrado no cartão CNPJ):"
				subtitle="Informe o endereço completo da empresa: Rua, número, complemento (se houver), bairro, cidade e estado."
				required
			>
				<Box
					css={{
						width: '50%',
					}}
				>
					<TextField
						label="CEP"
						type="text"
						mask={'ddddd-ddd'}
						value={zipCode}
						error={!!errors?.business_address?.zip_code}
						helperText={errors.business_address?.zip_code?.message}
						endAdornment={isLoadingCepPromise && <Icon name="Refresh" fontSize="$large" />}
						{...register('business_address.zip_code', {
							required: {
								value: true,
								message: 'Preenchimento obrigatório.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'column',
						width: '50%',
						marginTop: '$spacing24',
					}}
				>
					<TextField
						label="Endereço"
						maxLength={250}
						type="text"
						value={watch('business_address.address_line')}
						{...register('business_address.address_line', {
							required: {
								value: true,
								message: 'Preenchimento obrigatório.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Número"
							mask="ddddddddd"
							type="text"
							value={watch('business_address.building_number')}
							{...register('business_address.building_number', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled={!withNumber}
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Complemento"
							maxLength={150}
							type="text"
							value={watch('business_address.complement')}
							{...register('business_address.complement')}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ arginTop: '$spacing32', width: '50%' }}>
						<TextField
							label="Bairro"
							maxLength={200}
							type="text"
							value={watch('business_address.neighborhood')}
							{...register('business_address.neighborhood', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Cidade"
							type="text"
							value={watch('business_address.city')}
							{...register('business_address.city', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Estado"
							value={watch('business_address.state')}
							type="text"
							{...register('business_address.state', {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Checkbox
						name="without_number"
						label="Endereço sem número?"
						value="false"
						onChange={handleNoNumber}
						checked={!withNumber}
					/>
				</Box>
			</Card>
			<Box css={{ marginTop: '$spacing24', alignSelf: 'center' }}>
				<Button onClick={handleSubmitRetool} disabled={disabled} loading={loadingRetool}>
					Enviar
				</Button>
			</Box>
			<Toast
				ref={refToastError}
				direction="top-right"
				icon={<Icon name="Close" color="$currentColor" />}
				title="Erro ao enviar dados, tente novamente mais tarde."
				type="critical"
			/>
		</>
	);
};

export default Small;
