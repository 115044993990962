import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';

import { MixPanelEvents } from './types';

class MixpanelTracker {
	private static isInitialized = false;

	public static sendEvent(eventName: MixPanelEvents, eventProperties?: Dict) {
		const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

		if (process.env.NODE_ENV !== 'production' || !mixpanelToken) return;

		if (!MixpanelTracker.isInitialized) {
			mixpanel.init(mixpanelToken, {
				loaded: () => {
					MixpanelTracker.isInitialized = true;
				},
			});
		}

		mixpanel.track(eventName, {
			origin: process.env.REACT_APP_MIXPANEL_ORIGIN,
			...eventProperties,
		});
	}
}

export default MixpanelTracker;
