import { useCallback, useEffect, useState } from 'react';
import { BoxGroup } from './style';
import Card from '../../../../../../../components/Card';
import {
	Box,
	Checkbox,
	SelectField,
	SelectItem,
	TextField,
	Typography,
	unmaskString,
} from '@maistodos/design-system-web';
import { useFormContext } from 'react-hook-form';
import cep from 'cep-promise';
import { OwnerRepresentantLegalProps } from './types';
import { CompanyForm } from '../../../CompanyData/types';
import { ocupationsByBankly } from '../../../../../../../services/ocupations';
import { validator } from '../../../../../../../utils/validator';
import Icon from '../../../../../../../components/Icon';
import { declaredIncomeOptions, memberQualificationOptions } from '../../../../../constants';

const OwnerRepresentant = ({ parentIndex }: OwnerRepresentantLegalProps) => {
	const { register, watch, formState, setValue, setError } = useFormContext<CompanyForm>();
	const { errors } = formState;

	const [withNumber, setWithNumber] = useState<boolean>(true);
	const [isLoadingCepPromise, setisLoadingCepPromise] = useState<boolean>(false);
	const [isCepPromiseSuccess, setIsCepPromiseSuccess] = useState<boolean>(false);

	const zipCode = watch(`owners_business.${parentIndex}.legal_representatives.0.address.zip_code`);
	const viewAddress = isCepPromiseSuccess && zipCode?.length === 9;

	const handleZipCode = useCallback(async (): Promise<void> => {
		setIsCepPromiseSuccess(false);
		setisLoadingCepPromise(true);
		try {
			const address = await cep(zipCode);
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.address_line`, address.street);
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.city`, address.city);
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.neighborhood`, address.neighborhood);
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.state`, address.state);
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.country`, 'BR');
			setIsCepPromiseSuccess(true);
		} catch {
			setIsCepPromiseSuccess(false);
			setError(`owners_business.${parentIndex}.legal_representatives.0.address.zip_code`, {
				type: 'required',
				message: 'CEP inválido.',
			});
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.address_line`, '');
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.state`, '');
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.city`, '');
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.neighborhood`, '');
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.building_number`, '');
			setValue(`owners_business.${parentIndex}.legal_representatives.0.address.complement`, '');
			setWithNumber(true);
		} finally {
			setisLoadingCepPromise(false);
		}
	}, [cep, zipCode]);

	const handleNoNumber = useCallback(() => {
		setWithNumber((prev) => {
			const value = !prev;

			if (value)
				setValue(`owners_business.${parentIndex}.legal_representatives.0.address.building_number`, '', {
					shouldValidate: true,
				});
			if (!value)
				setValue(`owners_business.${parentIndex}.legal_representatives.0.address.building_number`, 'S/N', {
					shouldValidate: true,
				});

			return !prev;
		});
	}, [parentIndex]);

	useEffect(() => {
		if (zipCode?.length === 9) {
			void handleZipCode();
		}
	}, [zipCode]);

	return (
		<BoxGroup>
			<Typography variant={'h4'} weight={'semibold'} color={'$neutral600'} css={{ marginBottom: '$spacing24' }}>
				Representante Legal
			</Typography>
			<Card title="Nome de registro, como no documento (RG ou CNH):" subtitle="Conforme documento legal" required>
				<TextField
					label="Nome completo"
					type="text"
					maxLength={250}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.register_name`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.register_name}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.register_name?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.register_name`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 3,
							message: 'O campo não foi preenchido corretamente.',
						},
					})}
				/>
			</Card>
			<Card title="Nome de preferência (como podemos nos referir a você):" required>
				<TextField
					label="Nome de preferência"
					type="text"
					maxLength={250}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.social_name`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.social_name}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.social_name?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.social_name`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
				/>
			</Card>
			<Card title="CPF do representante legal (digite apenas os números):" required>
				<TextField
					label="CPF do representante legal"
					type="text"
					mask={'ddd.ddd.ddd-dd'}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.document_number`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.document_number}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.document_number?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.document_number`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 14,
							message: 'O campo não foi preenchido corretamente.',
						},
						validate: (e) => validator.isValidCPF(e),
					})}
				/>
			</Card>
			<Card title="Nome da mãe (insira o nome completo como no documento):" required>
				<TextField
					label="Nome da mãe"
					type="text"
					maxLength={250}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.mother_name`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.mother_name}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.mother_name?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.mother_name`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 6,
							message: 'O campo não foi preenchido corretamente.',
						},
					})}
				/>
			</Card>
			<Card title="E-mail" required>
				<TextField
					label="E-mail"
					type="text"
					maxLength={255}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.email`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.email}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.email?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.email`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'E-mail inválido.',
						},
					})}
				/>
			</Card>
			<Card title="Principal número celular para contato com DDD:" required>
				<TextField
					label="Telefone"
					type="text"
					mask="(dd) ddddd-dddd"
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.phone.number`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.phone?.number}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.phone?.number?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.phone.number`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 15,
							message: 'O preenchimento é obrigatório.',
						},
						validate: (e) => validator.isValidPhone(unmaskString(e)) || 'Número de celular inválido.',
					})}
				/>
			</Card>
			<Card
				title="Data de nascimento. Siga o modelo de preenchimento DD/MM/AAAA:"
				subtitle="É necessário ser maior de 18 anos."
			>
				<TextField
					label="Data de nascimento"
					type="text"
					mask="dd/dd/dddd"
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.birth_date`)}
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.birth_date}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.birth_date?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.birth_date`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
						minLength: {
							value: 10,
							message: 'O campo não foi preenchido corretamente.',
						},
						validate: (e) => validator.isValidDate(e, 'birthDate'),
					})}
				/>
			</Card>
			<Card title="Qualificação da pessoa física" required>
				<SelectField
					placeholder="Selecione a qualificação"
					error={errors && !!errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.member_qualification}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.member_qualification?.message}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.member_qualification`)}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.member_qualification`, {
						required: {
							value: true,
							message: 'É necessário informar a qualificação da pessoa física.',
						},
					})}
					onChange={(e) =>
						setValue(`owners_business.${parentIndex}.legal_representatives.0.member_qualification`, e.target.value, {
							shouldValidate: true,
						})
					}
				>
					{memberQualificationOptions.map((option) => (
						<SelectItem value={option.value} key={option.value}>
							{option.label}
						</SelectItem>
					))}
				</SelectField>
			</Card>
			<Card title="Faixa de renda declarada" required>
				<SelectField
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.declared_income`)}
					placeholder="Selecione a faixa de renda declarada"
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.declared_income}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.declared_income?.message}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.declared_income`, {
						required: {
							value: true,
							message: 'O preenchimento é obrigatório.',
						},
					})}
					onChange={(e) =>
						setValue(`owners_business.${parentIndex}.legal_representatives.0.declared_income`, e.target.value, {
							shouldValidate: true,
						})
					}
				>
					{declaredIncomeOptions.map((option) => (
						<SelectItem value={option.value} key={option.value}>
							{option.label}
						</SelectItem>
					))}
				</SelectField>
			</Card>
			<Card title="Pessoa politicamente exposta" required>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name={`owners_business.${parentIndex}.legal_representatives.0.pep.level`}
						label="Não sou uma pessoa politicamente exposta"
						value="none"
						onChange={() => setValue(`owners_business.${parentIndex}.legal_representatives.0.pep.level`, 'none')}
						checked={watch(`owners_business.${parentIndex}.legal_representatives.0.pep.level`) === 'none'}
					/>
				</Box>
				<Box css={{ marginBottom: '$spacing24' }}>
					<Checkbox
						name={`owners_business.${parentIndex}.legal_representatives.0.pep.level`}
						label="Sou uma pessoa politicamente exposta"
						value="self"
						onChange={() => setValue(`owners_business.${parentIndex}.legal_representatives.0.pep.level`, 'self')}
						checked={watch(`owners_business.${parentIndex}.legal_representatives.0.pep.level`) === 'self'}
					/>
				</Box>
				<Box>
					<Checkbox
						name={`owners_business.${parentIndex}.legal_representatives.0.pep.level`}
						label="Possuo relação com pessoa politicamente exposta"
						value="related"
						onChange={() => setValue(`owners_business.${parentIndex}.legal_representatives.0.pep.level`, 'related')}
						checked={watch(`owners_business.${parentIndex}.legal_representatives.0.pep.level`) === 'related'}
					/>
				</Box>
			</Card>
			<Card title="Ocupação principal" required>
				<SelectField
					placeholder="Selecione a ocupação principal"
					error={!!errors?.owners_business?.[parentIndex]?.legal_representatives?.[0]?.occupation}
					helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.occupation?.message}
					value={watch(`owners_business.${parentIndex}.legal_representatives.0.occupation`)}
					{...register(`owners_business.${parentIndex}.legal_representatives.0.occupation`, {
						required: {
							value: true,
							message: 'É necessário informar a ocupação principal.',
						},
					})}
					onChange={(e) =>
						setValue(`owners_business.${parentIndex}.legal_representatives.0.occupation`, e.target.value, {
							shouldValidate: true,
						})
					}
				>
					{ocupationsByBankly().map((item) => (
						<SelectItem value={item.id} key={item.id}>
							{item.description}
						</SelectItem>
					))}
				</SelectField>
			</Card>
			<Card
				title="Endereço completo do representante legal:"
				subtitle="Informe o endereço completo do representante legal: Rua, número, complemento (se houver), bairro, cidade e estado."
				required
			>
				<Box
					css={{
						width: '50%',
					}}
				>
					<TextField
						label="CEP"
						type="text"
						mask={'ddddd-ddd'}
						value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.zip_code`)}
						endAdornment={isLoadingCepPromise && <Icon name="Refresh" fontSize="$large" />}
						error={errors && !!errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.address?.zip_code}
						helperText={errors.owners_business?.[parentIndex]?.legal_representatives?.[0]?.address?.zip_code?.message}
						{...register(`owners_business.${parentIndex}.legal_representatives.0.address.zip_code`, {
							required: {
								value: true,
								message: 'É necessário preencher com CEP.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'column',
						width: '50%',
						marginTop: '$spacing24',
					}}
				>
					<TextField
						label="Endereço"
						maxLength={250}
						type="text"
						value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.address_line`)}
						{...register(`owners_business.${parentIndex}.legal_representatives.0.address.address_line`, {
							required: {
								value: true,
								message: 'Preenchimento obrigatório.',
							},
						})}
					/>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Número"
							mask="ddddddddd"
							type="text"
							value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.building_number`)}
							{...register(`owners_business.${parentIndex}.legal_representatives.0.address.building_number`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled={!withNumber}
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Complemento"
							maxLength={150}
							type="text"
							value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.complement`)}
							{...register(`owners_business.${parentIndex}.legal_representatives.0.address.complement`)}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ arginTop: '$spacing32', width: '50%' }}>
						<TextField
							label="Bairro"
							maxLength={200}
							type="text"
							value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.neighborhood`)}
							{...register(`owners_business.${parentIndex}.legal_representatives.0.address.neighborhood`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', width: '50%' }}>
						<TextField
							label="Cidade"
							type="text"
							value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.city`)}
							{...register(`owners_business.${parentIndex}.legal_representatives.0.address.city`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
					<Box css={{ marginTop: '$spacing24', marginBottom: '$spacing24', marginLeft: '$spacing16', width: '50%' }}>
						<TextField
							label="Estado"
							value={watch(`owners_business.${parentIndex}.legal_representatives.0.address.state`)}
							type="text"
							{...register(`owners_business.${parentIndex}.legal_representatives.0.address.state`, {
								required: {
									value: true,
									message: 'Preenchimento obrigatório.',
								},
							})}
							disabled
						/>
					</Box>
				</Box>
				<Box
					css={{
						display: viewAddress ? 'flex' : 'none',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Checkbox
						name={`owners_business.${parentIndex}.legal_representatives.0.without_number`}
						label="Endereço sem número?"
						value="false"
						onChange={handleNoNumber}
						checked={!withNumber}
					/>
				</Box>
			</Card>
		</BoxGroup>
	);
};

export default OwnerRepresentant;
